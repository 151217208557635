import { colors } from "utils";

export const Counter = ({ number }) => {
  if (number === null || number === undefined) {
    return null;
  }
  const numberValue = number.toLocaleString();
  return (
    <div style={{
      alignSelf: "center",
      display: "inline-flex",
      backgroundColor: colors.blue,
      borderRadius: "100px",
      justifyContent: "center",
      color: colors.lightblue,
      fontSize: "10px",
      height: "13px",
      minWidth: "max-content",
      padding: "0 3px",
    }}>
      {numberValue}
    </div>
  );
};
