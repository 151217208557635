import styled from "styled-components";
import { VideoTrack } from "../VideoTrack/VideoTrack";

export const Video = ({ style = {}, track, isLocal, priority }) => {
  return (
    <Wrapper style={style}>
      <Content>
        {track && (
          <VideoTrack track={track} isLocal={isLocal} priority={priority} />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  padding-bottom: calc(9 / 16 * 100%);
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: linear-gradient(to bottom, #0f2027, #203a43, #2c5364);

  background-size: cover;
`;
