import useParticipants from "hooks/livekit/useParticipants/useParticipants";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { useSessionContext } from "hooks/useSession";

export const useMembers = ({ includeObservers } = {}) => {
  const participants = useParticipants();
  const { session } = useSessionContext();

  const {
    room: { localParticipant },
  } = useVideoContext();

  if (!localParticipant) return { members: [] };

  localParticipant.userId = localParticipant.identity.split(":").pop();

  const members = [
    ...participants.map((participant) => {
      return {
        participant,
        isLocalParticipant: false,
        userId: participant.identity.split(":").pop(),
      };
    }),
    {
      isLocalParticipant: true,
      participant: localParticipant,
      userId: localParticipant.identity.split(":").pop(),
    },
  ]
    .filter((member) => !member.participant.identity.includes("spectator"))
    .filter((member) => !member.participant.identity.includes("tts"))
    .filter((member) => !member.participant.identity.includes("stt"))
    .filter((member) => !member.participant.identity.includes("observer"))
    .filter(
      (member) =>
        !!includeObservers || session?.observers?.[member.userId] !== true
    )
    .sort(function (a, b) {
      if (b.userId === session?.creatorId) return 999;
      if (a.userId === session?.creatorId) return -999;

      return a.userId.localeCompare(b.userId);
    });

  return { members };
};
