import "./multipleChoiceQuestion.css"
import { H6 } from "@bootstrap-styled/v4/";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { AnswerOptions } from "./AnswerOptions";
import { Standards } from "./Standards";
import { PreviewMultipleChoiceQuestionModal } from "./PreviewMultipleChoiceQuestionModal";

export const MultipleChoiceQuestion = ({ challenge, index, showHeader }) => {
  const [prompt, setPrompt] = useState(challenge.prompt);
  const [spokenPrompt, setSpokenPrompt] = useState(challenge.spoken_prompt);
  const [difficulty, setDifficulty] = useState(challenge.difficulty);
  const [solutionIndex, setSolutionIndex] = useState(challenge.solution_index);
  const [answerOptions, setAnswerOptions] = useState(challenge.answer_options);
  const [randomizeOptions, setRandomizeOptions] = useState(challenge.randomize_options);
  const [promptImageUrl, setPromptImageUrl] = useState(challenge.prompt_image_url);
  const [standards, setStandards] = useState(challenge.standards ?? []);
  const [comments, setComments] = useState(challenge.comments);
  const [renderType, setRenderType] = useState(challenge.render_type);
  const [status, setStatus] = useState(challenge.status ?? "active");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const buildChallengeRequest = () => {
    const request = {};
    request.id = challenge.id;
    request.difficulty = difficulty;
    request.standards = standards;
    request.comments = comments;
    request.status = status;
    request.prompt = prompt;
    request.spoken_prompt = spokenPrompt;
    request.solution_index = solutionIndex;
    request.answer_options = answerOptions;
    request.randomize_options = randomizeOptions === true;
    request.prompt_image_url = promptImageUrl;
    request.render_type = renderType;
    return request;
  };

  const handlePreview = () => {
    setIsPreviewModalOpen(true);
  };

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  }

  const handleSpokenPrompt = (e) => {
    setSpokenPrompt(e.target.value);
  }

  const handleDifficulty = (e) => {
    setDifficulty(e.target.value);
  }

  const handleRandomizeOptions = (e) => {
    setRandomizeOptions(e.target.checked);
  }

  const handlePromptImageUrl = (e) => {
    setPromptImageUrl(e.target.value);
  }

  const handleComments = (e) => {
    setComments(e.target.value);
  }

  const handleRenderType = (e) => {
    setRenderType(e.target.value);
  }

  const handleStatus = (e) => {
    setStatus(e.target.value);
  }

  const addAnswerOption = () => {
    const newAnswerOptions = JSON.parse(JSON.stringify(answerOptions));
    newAnswerOptions.push("");
    setAnswerOptions(newAnswerOptions);
  }

  const addStandard = () => {
    const newStandards = JSON.parse(JSON.stringify(standards));
    newStandards.push("");
    setStandards(newStandards);
  }

  return (
    <div className="multiple-choice-question">
      <Accordion.Item eventKey={index} key={index}>
        {showHeader && <Accordion.Header>
          ID: {challenge.id}&nbsp;&nbsp;&nbsp;
          Prompt: {prompt}&nbsp;&nbsp;&nbsp;
          Updated At: {challenge.updated_at}&nbsp;&nbsp;&nbsp;
          Updated By: {challenge.updated_by}&nbsp;&nbsp;&nbsp;
          Status: {status.toUpperCase()}&nbsp;&nbsp;&nbsp;
        </Accordion.Header>}
        <Accordion.Body>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div>
              <div className="challenge-prop">
                <H6>Prompt</H6>
                <input className="full-width round-box" type="text" value={prompt} onChange={handlePrompt}/>
              </div>
              <div className="challenge-prop">
                <H6>Spoken Prompt</H6>
                <input className="full-width round-box" type="text" value={spokenPrompt} onChange={handleSpokenPrompt}/>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="challenge-prop half-width">
                <AnswerOptions answerOptions={answerOptions}
                               setAnswerOptions={setAnswerOptions}
                               solutionIndex={solutionIndex}
                               setSolutionIndex={setSolutionIndex}
                />
                <button style={{ borderRadius: "100px", borderWidth: "1px", marginLeft: "18px" }}
                        onClick={addAnswerOption}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Add answer option
                </button>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="challenge-prop">
                  <Standards standards={standards} setStandards={setStandards}/>
                  <button className="round-box" onClick={addStandard}>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Add standard
                  </button>
                </div>
              </div>
              <div style={{ marginLeft: "15px", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div className="challenge-prop horizontal">
                  <H6 className="header-margin-right">Difficulty</H6>
                  <input className="input-number round-box"
                         type="number"
                         min="1"
                         max="5"
                         value={difficulty}
                         onChange={handleDifficulty}/>
                </div>
                <div className="challenge-prop horizontal">
                  <H6 className="header-margin-right">Randomize Options</H6>
                  <input type="checkbox"
                         checked={randomizeOptions === true}
                         onClick={handleRandomizeOptions}/>
                </div>
                <div className="challenge-prop horizontal">
                  <H6 className="header-margin-right">Render Type</H6>
                  <select className="select"
                          id="renderType"
                          name="renderType"
                          value={renderType}
                          onChange={handleRenderType}>
                    <option value="fraction_legacy">Fraction Legacy</option>
                    <option value="math">Math</option>
                  </select>
                </div>
                <div className="challenge-prop horizontal">
                  <H6 className="header-margin-right">Status</H6>
                  <select id="status"
                          name="status"
                          value={status}
                          onChange={handleStatus}>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="challenge-prop horizontal">
              <H6 className="image-url-width">Prompt Image URL</H6>
              <input className="full-width round-box"
                     type="url"
                     value={promptImageUrl}
                     onChange={handlePromptImageUrl}/>
            </div>
            <div className="challenge-prop horizontal">
              <H6 className="header-margin-right">Comments</H6>
              <input className="full-width round-box"
                     type="text"
                     value={comments}
                     onChange={handleComments}/>
            </div>
          </div>
          <div className="preview-button-div" >
            <button className="preview-button round-box" onClick={handlePreview}>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;Preview challenge in rendering mode and save changes
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <PreviewMultipleChoiceQuestionModal challenge={buildChallengeRequest()}
                                          isModalOpen={isPreviewModalOpen}
                                          setIsModalOpen={setIsPreviewModalOpen}/>
    </div>
  )
}