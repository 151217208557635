import {
  CardBlock,
  Card,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
} from "@bootstrap-styled/v4";

import { useFormData } from "hooks/useFormData/useFormData";
import { useActivity } from "hooks/useActivity/useActivity";

export const Settings = ({ activityId }) => {
  const { activity, loading, update } = useActivity(activityId);

  return (
    <Card>
      <CardBlock>
        {!loading && <SettingForm activity={activity} update={update} />}
      </CardBlock>
    </Card>
  );
};

const SettingForm = ({ activity, update }) => {
  const { formData, onChange } = useFormData({
    initial: {
      disableGestureOverlay: !!activity?.disableGestureOverlay,
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    update(formData);
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label check>
          <Input
            type="checkbox"
            name="disableGestureOverlay"
            checked={formData.disableGestureOverlay}
            onChange={onChange}
          />
          Disable Gesture Overlay
        </Label>
      </FormGroup>

      <Button type="submit">Publish</Button>
    </Form>
  );
};
