import { useSessionContext } from "hooks/useSession";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";

export const useObserver = (userId) => {
  const { authUserData } = useAuthUserData();
  const { session, update: updateSession } = useSessionContext();

  userId = userId || authUserData?.id;

  const observers = session?.observers;
  const isObserver = observers?.[userId] === true;

  const toggleUserObserver = () => {
    updateSession({ observers: { [userId]: !isObserver } });
  };

  return { toggleUserObserver, isObserver };
};
