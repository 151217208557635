import { useCallback, useEffect, useState } from "react";
import { SELECTED_AUDIO_OUTPUT_KEY } from "config/livekit";
import useDevices from "hooks/livekit/useDevices/useDevices";

export default function useActiveAudioSinkId() {
  const { audioOutputDevices } = useDevices();
  const [activeAudioSinkId, _setActiveAudioSinkId] = useState("default");

  const setActiveAudioSinkId = useCallback(
    (sinkId) => {
      window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
      _setActiveAudioSinkId(sinkId);
    },
    [_setActiveAudioSinkId]
  );

  useEffect(() => {
    const selectedSinkId = window.localStorage.getItem(
      SELECTED_AUDIO_OUTPUT_KEY
    );
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      (device) => selectedSinkId && device.deviceId === selectedSinkId
    );
    if (hasSelectedAudioOutputDevice) {
      _setActiveAudioSinkId(selectedSinkId);
    }
  }, [audioOutputDevices]);

  return [activeAudioSinkId, setActiveAudioSinkId];
}
