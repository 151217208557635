import { useState } from "react";
import styled from "styled-components";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import useParticipants from "hooks/livekit/useParticipants/useParticipants";
import { stringToColor } from "utils";

export const DebugPanel = () => {
  const [eventHistory, setEventHistory] = useState([]);
  const [isGazeFiltered, setGazeFiltered] = useState(false);
  const [isNullFiltered, setNullFiltered] = useState(false);
  const [isGestureFiltered, setGestureFiltered] = useState(false);
  const [isSpeechFiltered, setSpeechFiltered] = useState(false);

  const participants = useParticipants();

  useDataTrack((e) => {
    if (isGazeFiltered && e?.data?.gesture_type === "GazeDirection") return;
    if (isNullFiltered && e?.data?.detection === null) return;
    if (isGestureFiltered && e?.event_type === "gesture") return;
    if (isSpeechFiltered && e?.event_type.includes("speech")) return;

    const item = ["i" + Math.random(), new Date(), e];
    const history = [item, ...eventHistory];
    const constrain = history.slice(
      0,
      eventHistory.length > 15 ? -1 : eventHistory.length + 1
    );

    setEventHistory(constrain);
  });

  return (
    <Container>
      <div style={{ height: "100%" }}>
        <div className="d-flex mb-2">
          <button
            className="mr-2"
            onClick={() => setGazeFiltered(!isGazeFiltered)}
          >
            Gaze filtering is {isGazeFiltered ? "on" : "off"}
          </button>
          <button
            className="mr-2"
            onClick={() => setNullFiltered(!isNullFiltered)}
          >
            Null Detection filtering is {isNullFiltered ? "on" : "off"}
          </button>
          <button
            className="mr-2"
            onClick={() => setSpeechFiltered(!isSpeechFiltered)}
          >
            Speech filtering is {isSpeechFiltered ? "on" : "off"}
          </button>
          <button
            className="mr-2"
            onClick={() => setGestureFiltered(!isGestureFiltered)}
          >
            Gesture filtering is {isGestureFiltered ? "on" : "off"}
          </button>
          <button className="mr-2" onClick={() => setEventHistory([])}>
            Clear
          </button>
        </div>

        <div
          style={{
            background: "white",
            height: 200,
            overflowY: "scroll",
            marginBottom: 10,
          }}
        >
          {eventHistory.map((e) => (
            <div
              key={e[0]}
              style={{ fontSize: 10 }}
              dangerouslySetInnerHTML={{ __html: prettPrint(e) }}
            ></div>
          ))}
        </div>

        <div
          style={{
            fontSize: 10,

            padding: 10,
            display: "flex",
          }}
        >
          {participants.map((participant) => (
            <div
              key={participant.sid}
              style={{
                margin: 5,
                background: "white",
                padding: 5,
                maxHeight: 200,
                overflowY: "scroll",
              }}
            >
              <div>
                sid: {participant.sid}, identity: {participant.identity}, state:{" "}
                {participant.state}
              </div>
              <code style={{ fontSize: 8, whiteSpace: "pre" }}>
                {JSON.stringify(
                  JSON.parse(JSON.stringify(participant)),
                  null,
                  2
                )}
              </code>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 130px;
`;

const prettPrint = (e) => {
  const text = JSON.stringify(e[2]);

  const id = e[2]?.data.participant_id || "";

  const title = `<span style = "color:${stringToColor(id)}" >
    ${id.slice(-5)}
  </span>`;

  const style = `style = 'text-decoration:underline;text-direction:underline;font-weight:700;color:`;

  return (
    title +
    ": " +
    text
      .replace(`GazeDirection`, `<span ${style}navy'>GazeDirection</span>`)
      .replace(`thumbs_up`, `<span ${style}red'>thumbs_up</span>`)
      .replace(`hand_raise`, `<span ${style}blue'>hand_raise</span>`)
      .replace(`two`, `<span ${style}pink'>two</span>`)
      .replace(`three`, `<span ${style}tomato'>three</span>`)
      .replace(`four`, `<span ${style}brown'>four</span>`)
      .replace(`five`, `<span ${style}RebeccaPurple'>five</span>`)
  );
};
