import styled from "styled-components";

import { faTachometerAlt, faRobot } from "@fortawesome/free-solid-svg-icons";

import { ActivitiesMenu } from "./ActivitiesMenu";
import { ActivityButton } from "./ActivityButton";

import { HomePanel } from "../HomePanel/HomePanel";
import { OkoLessonPanel } from "../OkoLessonPanel/OkoLessonPanel";

export const Sidebar = ({ changeToPanel }) => {
  return (
    <Wrapper>
      <div style={{ flex: 1 }}>
        <ActivityButton
          activity={{
            label: "Dashboard",
            icon: faTachometerAlt,
          }}
          onClick={() =>
            changeToPanel({ label: "Dashboard", component: HomePanel })
          }
        />

        <ActivityButton
          activity={{
            label: "OKO Lesson",
            icon: faRobot,
          }}
          onClick={() =>
            changeToPanel({ label: "OKO Lesson", component: OkoLessonPanel })
          }
        />

        <ActivitiesMenu changeToPanel={changeToPanel} />
      </div>
      <div>{/* <Logo> Oko Labs</Logo> */}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #293042;
  width: 260px;
  padding: 10px;
  position: relative;
  padding-top: ${(260 / window.innerWidth) * window.innerHeight + 10}px;
`;
