import { useFirebaseRef as useCalculatorFirebaseRef } from "../useCalculatorActivity/useCalculatorActivity";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const useFirebaseRef = (userId) => {
  const { authUserData } = useAuthUserDataContext();
  const activityRef = useCalculatorFirebaseRef();
  const ref = activityRef.collection("users").doc(userId || authUserData?.id);

  return activityRef && authUserData ? ref : null;
};

export const useUserCalculator = (userId) => {
  const ref = useFirebaseRef(userId);
  const [calculator, loading, error] = useDocumentData(ref, { idField: "id" });

  const update = (data) => {
    return ref.set(data, { merge: true });
  };

  const set = (data) => {
    return ref.set(data);
  };

  return { calculator, loading, error, update, set };
};

export const useUserCalculatorUpdate = () => {
  const activityRef = useCalculatorFirebaseRef();

  const update = (userId, data, clearHistory) => {
    const ref = activityRef.collection("users").doc(userId);

    if (clearHistory) {
      ref
        .collection("history")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref.delete();
          });
        });
    }

    ref.set(data, { merge: true });
  };

  return { update };
};
