import { useEffect } from 'react';
import { RoomEvent } from "livekit-client";
export default function useHandleOnDisconnect(room, onDisconnect) {
    useEffect(() => {
        room.on(RoomEvent.Disconnected, onDisconnect);
        return () => {
            room.off(RoomEvent.Disconnected, onDisconnect);
        };
    }, [room, onDisconnect]);
}
