import styled from "styled-components";
import { useParticipantMedia } from "hooks/useParticipantMedia/useParticipantMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideoSlash,
  faVideo,
  faMicrophoneSlash,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useObserver } from "hooks/useObserver/useObserver";
import { Toggle } from "components/shared/Toggle/Toggle";

export const VideoBarWidget = ({ participant }) => {
  const {
    videoDisabled,
    audioDisabled,
    toggleParticipantVideo,
    toggleParticipantAudio,
  } = useParticipantMedia(participant);

  const { isObserver, toggleUserObserver } = useObserver(participant.userId);

  return (
    <Wrapper>
      <div className="d-flex">
        <Toggle
          className="mr-2"
          checked={videoDisabled}
          icons={{
            checked: <FontAwesomeIcon size="xs" icon={faVideoSlash} />,
            unchecked: <FontAwesomeIcon size="xs" icon={faVideo} />,
          }}
          colors={{
            checked: "#AB1919",
          }}
          onChange={toggleParticipantVideo}
        />
        <Toggle
          checked={audioDisabled}
          icons={{
            checked: <FontAwesomeIcon size="xs" icon={faMicrophoneSlash} />,
            unchecked: <FontAwesomeIcon size="xs" icon={faMicrophone} />,
          }}
          colors={{
            checked: "#AB1919",
          }}
          onChange={toggleParticipantAudio}
        />
      </div>
      <div className="d-flex">
        <Toggle
          checked={isObserver}
          icons={{
            checked: <FontAwesomeIcon size="xs" icon={faEyeSlash} />,
            unchecked: <FontAwesomeIcon size="xs" icon={faEye} />,
          }}
          colors={{
            checked: "#AB1919",
          }}
          onChange={toggleUserObserver}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #3f434a;

  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;

  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
`;
