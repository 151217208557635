import "./login.css";
import { Card } from "@bootstrap-styled/v4";
import { OKOFooter } from "../../OKOFooter";
import { colors } from "utils";

export const LoginCard = ({ children }) => {
  return (
    <div style={{ backgroundColor: colors.milkGlass200, display: "contents" }}>
      <Card className="login-card login-card-small-width">
        {children}
      </Card>
      <OKOFooter />
    </div>
  );
};
