import { useEffect, useState } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";
import { RoomEvent } from "livekit-client";

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState(RoomEvent.Disconnected);
  useEffect(() => {
    const setRoomState = () => setState(room.state || RoomEvent.Disconnected);
    setRoomState();
    room
      .on(RoomEvent.Disconnected, setRoomState)
      .on(RoomEvent.Reconnected, setRoomState)
      .on(RoomEvent.Reconnecting, setRoomState);
    return () => {
      room
        .off(RoomEvent.Disconnected, setRoomState)
        .off(RoomEvent.Reconnected, setRoomState)
        .off(RoomEvent.Reconnecting, setRoomState);
    };
  }, [room]);
  return state;
}
