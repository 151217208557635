export const stringToColor = (str) => {
  str = str || "";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

export const isEveryAllowedCharInString = (input, allowedChars) => {
  let result = true;
  const chars = `${input}`.split("");
  chars.forEach((c) => {
    if (!allowedChars.includes(c)) {
      result = false;
    }
  });
  return result;
};
