import { H6 } from "@bootstrap-styled/v4";
import { AnswerOption } from "./AnswerOption";

export const AnswerOptions = ({ answerOptions, setAnswerOptions, solutionIndex, setSolutionIndex }) => {

  return (
    <div>
      <H6>Answer Options (Radio button chooses correct answer)</H6>
      {answerOptions && answerOptions.map((answerOption, index) => (
        <AnswerOption index={index}
                      answerOptions={answerOptions}
                      setAnswerOptions={setAnswerOptions}
                      solutionIndex={solutionIndex}
                      setSolutionIndex={setSolutionIndex}
        />
      ))}
    </div>
  );
};
