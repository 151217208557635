import { useEffect } from "react";

export const MathJax = ({isMathJaxSet, expression}) => {
  useEffect(()=> {
    if (!isMathJaxSet && typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset()
    }
  },[]);
  return (
    <span>{expression}</span>
  );
};
