import styled from "styled-components";

import { useCalculatorActivityContext } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";
import { Calculator } from "../Calculator/Calculator";
import { Instructions } from "../Instructions/Instructions";
import { History } from "../History/History";
import { PresentingCalculatorWrapper } from "./PresentingCalculatorWrapper";
import { useSessionContext } from "hooks/useSession";
import { ShareScreen } from "components/pages/Session/Activity/activities/ShareScreen/ShareScreen";
import { ShareScreenToggle } from "./ShareScreenToggle";
import useScreenShareParticipant from "hooks/livekit/useScreenShareParticipant/useScreenShareParticipant";
import { usePresentingUser } from "hooks/activities/calculator/usePresentingUser/usePresentingUser";
import { useCalculator } from "hooks/activities/calculator/useCalculator/useCalculator";

export const Activity = () => {
  const { loading } = useCalculatorActivityContext();
  const { userIsSessionAdmin } = useSessionContext();
  const screenShareParticipant = useScreenShareParticipant()
  const { set } = useCalculator();
  const { presentingUserId } = usePresentingUser();

  return (
    <Wrapper>
      {!loading && (
        <>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <PresentingCalculatorWrapper>
                <Instructions />
                <div className="d-flex">
                  <Calculator />
                  <HistoryWrapper>
                    <History
                      userId={presentingUserId}
                      onItemClick={(h) => set(h.formula)}
                    />
                  </HistoryWrapper>
                </div>
              </PresentingCalculatorWrapper>
            </div>
            {!userIsSessionAdmin && screenShareParticipant && (
              <div className="flex-grow-1">
                <ShareScreen style={{ minHeight: "100%" }} />
              </div>
            )}
          </div>

          {userIsSessionAdmin && <ShareScreenToggle />}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HistoryWrapper = styled.div`
  width: 260px;
`;
