import { useCallback, useState } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";
import {Track} from "livekit-client";

export default function useLocalAudioToggle() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track?.source.includes(Track.Source.Microphone));
  const [isMuted, setIsMuted] = useState(audioTrack?.isMuted);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (audioTrack.isMuted) {
        audioTrack.unmute();
        setIsMuted(false);
      } else {
        audioTrack.mute();
        setIsMuted(true);
      }
    }
  }, [audioTrack]);
  return { isMuted, toggleAudioEnabled };
}
