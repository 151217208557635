import React, { createContext, useState, useEffect } from 'react';
import { RoomEvent } from "livekit-client";
export const selectedParticipantContext = createContext(null);
export function SelectedParticipantProvider({ room, children }) {
    const [selectedParticipant, _setSelectedParticipant] = useState(null);
    const setSelectedParticipant = (participant) => _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : participant));
    useEffect(() => {
        const onDisconnect = () => _setSelectedParticipant(null);
        const handleParticipantDisconnected = (participant) => _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : prevParticipant));
        room.on(RoomEvent.Disconnected, onDisconnect);
        room.on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected);
        return () => {
            room.off(RoomEvent.Disconnected, onDisconnect);
            room.off(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected);
        };
    }, [room]);
    return (<selectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </selectedParticipantContext.Provider>);
}
