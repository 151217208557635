import { colors } from "utils";
import { Button } from "react-bootstrap";
import IconCopySimple from "assets/Icon-CopySimple.svg";

export const GroupCodeButton = ({ code }) => {
  const handleClick = (e) => {
    navigator.clipboard.writeText(e.target.innerText);
  }
  return (
    <div>
      <Button style={{
        backgroundColor: colors.blue,
        border: "none",
        borderRadius: "6px",
        fontSize: "12px",
        height: "32px",
        padding: "0 16px" }} onClick={handleClick}>
        <div style={{ alignItems: "center", display: "flex", gap: "4px", justifyContent: "center" }}>
          <img src={IconCopySimple} />
          {code.toUpperCase()}
        </div>
      </Button>
    </div>
  );
};
