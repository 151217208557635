import { useEffect, useRef } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { useSessionContext } from "hooks/useSession";
import { H6, Button } from "@bootstrap-styled/v4/";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import useScreenShareParticipant from "hooks/livekit/useScreenShareParticipant/useScreenShareParticipant";
import { Participant } from "components/pages/Session/shared/Participant/Participant";

export const ShareScreen = ({ style = {} }) => {
  const { userIsSessionAdmin } = useSessionContext();
  const { toggleScreenShare, isSharingScreen } = useVideoContext();
  const shareScreenRef = useRef();
  const screenShareParticipant = useScreenShareParticipant();
  const isScreenShareSupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

  useEffect(() => {
    shareScreenRef.current = { isSharingScreen, toggleScreenShare };
  }, [isSharingScreen, toggleScreenShare]);

  useEffect(() => {
    return () => {
      if (shareScreenRef.current.isSharingScreen) {
        shareScreenRef.current.toggleScreenShare();
      }
    };
  }, []);

  return (
    <StyledFullscreen style={style}>
      {!userIsSessionAdmin && (
        <>
          {!screenShareParticipant && <div>Waiting for screen share</div>}
          {screenShareParticipant && (
            <div style={{ width: "100%", padding: 30 }}>
              <Participant
                participant={screenShareParticipant}
                enableScreenShare={true}
              />
            </div>
          )}
        </>
      )}
      {userIsSessionAdmin && (
        <div>
          {!isScreenShareSupported && (
            <div>Your device does not support screen sharing</div>
          )}
          {isScreenShareSupported && !isSharingScreen && (
            <Button onClick={toggleScreenShare}>
              Select a screen to share
            </Button>
          )}

          {isSharingScreen && (
            <div>
              <H6>You are sharing your screen</H6>
              {screenShareParticipant && (
                <div style={{ width: "40vw" }}>
                  <Participant
                    participant={screenShareParticipant}
                    enableScreenShare={true}
                  />
                </div>
              )}
              <Button onClick={toggleScreenShare}>Stop Sharing</Button>
            </div>
          )}
        </div>
      )}
    </StyledFullscreen>
  );
};

const StyledFullscreen = styled(Fullscreen)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
