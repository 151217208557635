import styled from "styled-components";
import { GestureVideo } from "components/pages/Session/shared/GestureVideo/GestureVideo";
import { useMembers } from "hooks/livekit/useMembers/useMembers";

export const VideosBar = ({
  videoWidget,
  includeObservers = false,
  isSpectator,
}) => {
  const { members } = useMembers({ includeObservers });
  const VideoWidget = videoWidget;

  return (
    <Wrapper>
      {members.map(({ participant, isLocalParticipant }) => (
        <>
          {isSpectator ? (
            <ObserverVideoContainer key={participant.sid}>
              {videoWidget && <VideoWidget participant={participant} />}
              <GestureVideo
                participant={participant}
                isLocalParticipant={isLocalParticipant}
                isSpectator={isSpectator}
              />
            </ObserverVideoContainer>
          ) : (
            <VideoContainer key={participant.sid}>
              {videoWidget && <VideoWidget participant={participant} />}
              <GestureVideo
                participant={participant}
                isLocalParticipant={isLocalParticipant}
                isSpectator={isSpectator}
              />
            </VideoContainer>
          )}
        </>
      ))}
    </Wrapper>
  );
};

const gutter = 8;
const maxHeight = 180;
const ratio = 16 / 9;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${gutter}px;
`;

const VideoContainer = styled.div`
  position: relative;
  max-height: ${maxHeight}px;
  width: 100%;
  margin: 0px ${gutter / 2}px;
  max-width: ${(maxHeight - gutter * 2) * ratio}px;
`;

const ObserverVideoContainer = styled.div`
  position: relative;
  max-height: ${maxHeight}px;
  width: 100%;
  margin: 0px ${gutter / 2}px;
`;
