import { useContext, createContext, useEffect } from "react";
import { useFirebase } from "hooks/useFirebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";

export const useAuthUserData = () => {
  const firebase = useFirebase();
  const [authUser, authLoading, authError] = useAuthState(firebase.auth());
  const [docUser, docLoading, docError] = useDocument(
    authUser?.uid ? firebase.firestore().doc(`users/${authUser?.uid}`) : null
  );

  useEffect(() => {
    if (authLoading || authUser) return;
    firebase.auth().signInAnonymously();
  }, [authLoading, authUser, firebase]);

  let loading = authLoading || docLoading || docUser === undefined;
  const error = authError || docError;

  if (error) loading = false;

  const docUserData = docUser?.data();
  const authUserData = error || loading ? null : {
    id: authUser?.uid,
    emailVerified: authUser?.emailVerified,
    isAnonymous: authUser?.isAnonymous,
    displayName: authUser?.displayName || docUserData?.displayName,
    email: authUser?.email,
  };
  return { authUserData, loading, error };
};

const Context = createContext();

export const AuthUserDataProvider = ({ children }) => {
  const value = useAuthUserData();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAuthUserDataContext = () => {
  return useContext(Context);
};
