import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { prettyPrintFormula } from "utils";

export const Output = ({ formula = "", onClearClick = () => {} }) => {
  return (
    <Wrapper>
      <FontAwesomeIcon
        style={{ cursor: "pointer", opacity: 0.7, userSelect: "none" }}
        icon={faTrash}
        size="xs"
        onClick={() => {
          onClearClick();
        }}
      />
      <AnimatePresence exitBeforeEnter>
        {formula.length > 0 && (
          <motion.div
            key="display"
            style={{ fontSize: formulaFontSize(formula), fontWeight: 400 }}
            initial={{ x: 0, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 600, opacity: 0, transition: { duration: 0.8 } }}
          >
            {prettyPrintFormula(formula)}
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  font-family: Rubik, sans-serif;
  font-size: 36px;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  border-radius: 5px;
  padding: 5px 10px;
  color: rgb(63, 67, 74);
  background: #eff2f745;
  border: 1px solid rgba(220, 223, 226, 0.32);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px inset,
    rgb(255, 255, 255) 0px -1px 1px, rgb(255, 255, 255) 0px 1px 0px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
`;

const formulaFontSize = (formula) => {
  if (formula.length > 19) return 10;
  if (formula.length > 9) return 20;
  return 36;
};
