import { useEffect, useState } from "react";
import { RoomEvent } from "livekit-client";
export default function usePublications(participant) {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    if (!participant) return;

    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.trackPublications.values()));

    const publicationAdded = (publication) =>
      setPublications((prevPublications) => [...prevPublications, publication]);

    const publicationRemoved = (publication) =>
      setPublications((prevPublications) =>
        prevPublications.filter((p) => p !== publication)
      );

    participant.on(RoomEvent.TrackPublished, publicationAdded);
    participant.on(RoomEvent.LocalTrackPublished, publicationAdded);
    participant.on(RoomEvent.TrackUnpublished, publicationRemoved);
    participant.on(RoomEvent.LocalTrackUnpublished, publicationRemoved);

    return () => {
      participant.off(RoomEvent.TrackPublished, publicationAdded);
      participant.off(RoomEvent.LocalTrackPublished, publicationAdded);
      participant.off(RoomEvent.TrackUnpublished, publicationRemoved);
      participant.off(RoomEvent.LocalTrackUnpublished, publicationRemoved);
    };
  }, [participant]);

  return publications;
}
