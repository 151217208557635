import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Badge } from "@bootstrap-styled/v4";
import { evaluateMathExpression, prettyPrintFormula } from "utils";
import { useHistory } from "hooks/activities/calculator/useHistory/useHistory";
import { useLocalParticipantUserId } from "hooks/useLocalParticipant/useLocalParticipant";

export const History = ({ userId, onItemClick, height }) => {
  const localParticipantUserId = useLocalParticipantUserId();
  const { history } = useHistory(userId || localParticipantUserId);

  return (
    <AnimatePresence initial={false}>
      <List height={height}>
        {(history || []).map((h) => {
          let mathSolution = evaluateMathExpression(h.formula);

          return (
            <ListItem
              style={{ cursor: onItemClick ? "pointer" : "auto" }}
              key={h.id}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 64 }}
              onClick={() => onItemClick && onItemClick(h)}
            >
              <motion.div
                initial={{ x: -300 }}
                animate={{ x: 0 }}
                className="mr-auto"
              >
                {prettyPrintFormula(h.formula)}
              </motion.div>

              <div className="d-flex">
                {mathSolution === h.goal && (
                  <CorrectBadge>= {mathSolution}</CorrectBadge>
                )}

                {mathSolution !== h.goal && (
                  <>
                    <WrongBadge>= {mathSolution || "#"}</WrongBadge>
                    <GoalBadge>= {h.goal}</GoalBadge>
                  </>
                )}
              </div>
            </ListItem>
          );
        })}
      </List>
    </AnimatePresence>
  );
};

const List = styled.ul`
  background: rgb(220 223 226);
  width: 100%;
  height: ${(props) => (props.height || 380) + "px"};
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid rgb(220, 223, 226);
`;

const ListItem = styled(motion.li)`
  list-style-type: none;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  border-bottom: 1px solid rgb(220 223 226);
  padding: 12px 16px;
  color: #3f434a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f5f9;
  box-shadow: 0px 1px 10px 0px 1px 3px #000000;
  width: 100%;
  overflow: hidden;
`;

const StyledBadge = styled(Badge)`
  &.badge {
    font-weight: normal;
    background: #50b5ff;
  }
`;

const CorrectBadge = styled(StyledBadge)`
  &.badge {
    background: #37bd74;
  }
`;

const WrongBadge = styled(StyledBadge)`
  &.badge {
    background: rgb(248, 215, 218);
    color: rgb(114, 28, 36);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const GoalBadge = styled(StyledBadge)`
  &.badge {
    background: rgb(80, 181, 255);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
