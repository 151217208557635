import { useEffect, useState } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";
import { RoomEvent } from "livekit-client";

export default function useParticipants() {
  const { room } = useVideoContext();
  const [participants, setParticipants] = useState(
    Array.from(room?.remoteParticipants?.values() || []).map((p) =>
      addMetaFromIdentity(p)
    )
  );

  useEffect(() => {
    const participantConnected = (participant) =>
      setParticipants((prevParticipants) => [
        ...prevParticipants,
        addMetaFromIdentity(participant),
      ]);

    const participantDisconnected = (participant) =>
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p.sid !== participant.sid)
      );

    room.on(RoomEvent.ParticipantConnected, participantConnected);
    room.on(RoomEvent.ParticipantDisconnected, participantDisconnected);

    return () => {
      room.off(RoomEvent.ParticipantConnected, participantConnected);
      room.off(RoomEvent.ParticipantDisconnected, participantDisconnected);
    };
  }, [room, setParticipants]);

  return participants;
}

function addMetaFromIdentity(participant) {
  participant.userId = participant.identity.split(":").pop();
  return participant;
}
