import { useRef } from "react";
import { H6, ListGroup, ListGroupItem, Badge } from "@bootstrap-styled/v4";
import { Button, ButtonGroup } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faTrash,
  faFileExport,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { prettyPrintFormula } from "utils";

export const Presets = ({
  onSelect = () => {},
  presets,
  setPresets,
  addToPresets,
}) => {
  const fileInput = useRef(null);

  const savePresetsToFile = () => {
    const fileName = "calc-presets.json";
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(presets)], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  const handleLoadPresets = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;

      if (text) {
        const json = JSON.parse(text);
        if (json) {
          setPresets(json);
        }
      }
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center ">
        <H6 className="mb-0">Presets</H6>
        <ButtonGroup className="d-flex">
          <Button
            style={{ border: "none" }}
            outline
            onClick={() => {
              fileInput.current.click();
            }}
          >
            <FontAwesomeIcon icon={faFileDownload} />
            <input
              style={{ display: "none" }}
              type="file"
              onChange={(e) => handleLoadPresets(e)}
              ref={fileInput}
            />
          </Button>
          <Button
            style={{ border: "none" }}
            outline
            color="primary"
            onClick={() => {
              savePresetsToFile();
            }}
          >
            <FontAwesomeIcon icon={faFileExport} />
          </Button>
        </ButtonGroup>
      </div>

      <ListGroup style={{ maxHeight: 400, overflowY: "auto" }}>
        {presets.map((p, i) => (
          <ListGroupItem key={i} className="justify-content-between ">
            <ButtonGroup className="w-100">
              <Button
                style={{ flex: 1 }}
                className="flex-grow-1 text-left d-flex justify-content-between"
                onClick={() => onSelect(p)}
              >
                <span>
                  <FontAwesomeIcon icon={faBan} />
                  {prettyPrintFormula((p?.bannedButtonIds || []).join(","))}
                </span>

                <Badge className="ml-2" style={{ background: "#37BD74" }}>
                  ={p?.goal}
                </Badge>
              </Button>

              <Button
                style={{ border: "none" }}
                color="danger"
                onClick={() => {
                  setPresets(presets.filter((preset) => p !== preset));
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </ButtonGroup>
          </ListGroupItem>
        ))}
      </ListGroup>

      <Button
        block
        className="mt-2 w-100"
        color="secondary"
        onClick={() => {
          addToPresets();
        }}
      >
        Add to Presets
      </Button>
    </>
  );
};
