import {
  faMicrophoneSlash,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";

import { ActionButton } from "./ActionButton";
import useLocalAudioToggle from "hooks/livekit/useLocalAudioToggle/useLocalAudioToggle";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";

export const ToggleLocalMicButton = ({ style }) => {
  const { isMuted: isAudioMuted, toggleAudioEnabled } = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === "audio");

  return (
    <ActionButton
      onClick={toggleAudioEnabled}
      style={style}
      icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
      active={!isAudioMuted}
      tooltip={
        !hasAudioTrack
          ? "No Audio Device Found"
          : !isAudioMuted
          ? "Mute Microphone"
          : "Unmute Microphone"
      }
    />
  );
};
