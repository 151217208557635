import { useEffect, useState } from "react";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { RoomEvent } from "livekit-client";
/*
  Returns the participant that is sharing their screen (if any). This hook assumes that only one participant
  can share their screen at a time.
*/
export default function useScreenShareParticipant() {
  const { room } = useVideoContext();
  const [screenShareParticipant, setScreenShareParticipant] = useState();

  useEffect(() => {
    if (room.state === RoomEvent.Connected) {
      const updateScreenShareParticipant = () => {
        setScreenShareParticipant(
          Array.from(room.remoteParticipants.values())
            // the screenshare participant could be the localParticipant
            .concat(room.localParticipant)
            .find((participant) =>
              Array.from(participant.trackPublications.values()).find((track) =>
                track.trackName?.includes("screen")
              )
            )
        );
      };

      updateScreenShareParticipant();
      room.on(RoomEvent.TrackPublished, updateScreenShareParticipant);
      room.on(RoomEvent.TrackUnpublished, updateScreenShareParticipant);
      room.on(RoomEvent.ParticipantDisconnected, updateScreenShareParticipant);

      // the room object does not emit 'trackPublished' events for the localParticipant,
      // so we need to listen for them here.
      room.localParticipant.on(RoomEvent.TrackPublished, updateScreenShareParticipant);
      room.localParticipant.on(RoomEvent.TrackUnpublished, updateScreenShareParticipant);

      return () => {
        room.off(RoomEvent.TrackPublished, updateScreenShareParticipant);
        room.off(RoomEvent.TrackUnpublished, updateScreenShareParticipant);
        room.off(RoomEvent.ParticipantDisconnected, updateScreenShareParticipant);
        room.localParticipant.off(RoomEvent.TrackPublished, updateScreenShareParticipant);
        room.localParticipant.off(RoomEvent.TrackUnpublished, updateScreenShareParticipant);
      };
    }
  }, [room]);

  return screenShareParticipant;
}
