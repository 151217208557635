import { createContext, useContext } from "react";
import { useConsensusOkoSession } from "./useConsensusOkoSession";
import { useCalculatorOkoSession } from "./useCalculatorOkoSession";
import { useOkoApi, request } from "hooks/useOkoApi/useOkoApi";
import { useSessionContext, getRef as getSessionRef } from "hooks/useSession";

export const create = async (session) => {
  return request(`session`, {
    payload: { twilio_room_sid: session?.room?.sid }, // Removed from BE, used only in OkoLessonPanel which is disabled
  }).then((res) => {
    const result = { okoSessionId: res.session_id };
    return result;
  });
};

export const start = async (session) => {
  let okoSessionId = session?.okoSessionId;
  if (!okoSessionId) {
    const result = await create(session);
    okoSessionId = result.okoSessionId;
    await getSessionRef(session.id).set({okoSessionId}, {merge: true});
  }
};

export const useOkoSession = () => {
  const { fetch } = useOkoApi();
  const { session } = useSessionContext();
  const calculatorSession = useCalculatorOkoSession();
  const consensusSession = useConsensusOkoSession();

  const terminate = async () => {
    let okoSessionId = session?.okoSessionId;

    return fetch(`session/${okoSessionId}/task/terminate`, {
      payload: { session_id: okoSessionId },
    });
  };

  return { consensusSession, calculatorSession, create, start, terminate };
};

const Context = createContext();

export const OkoSessionProvider = ({ children }) => {
  const value = useOkoSession();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useOkoSessionContext = () => {
  return useContext(Context);
};
