import { useState } from "react";

export const useTrigger = () => {
  const [value, setValue] = useState(null);

  const dispatch = (name, target) => {
    setValue({ name, target });
  };

  return { trigger: value, dispatch };
};
