import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { request } from "./useOkoApi/useOkoApi";

export const useStandards = () => {
  const [standardsData, setStandardsData] = useState([]);
  useEffect(() => {
    const getStandardsData = () => {
      return request("questions/standards", {
        method: "GET",
      }).then((result) => {
        setStandardsData(result);
      }).catch((error) => {
        Sentry.captureException(error);
      });
    };
    getStandardsData();
  },[]);
  return standardsData;
};
