import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faBullhorn,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@bootstrap-styled/v4";
import { Toggle } from "components/shared/Toggle/Toggle";
import { History } from "../History/History";
import { useUserCalculator } from "hooks/activities/calculator/useUserCalculator/useUserCalculator";
import { prettyPrintFormula } from "utils";
import { useCalculatorActivityContext } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";

export const VideoUserStatus = ({ participant }) => {
  const userId = participant.userId;
  const [tooltip, setTooltip] = useState({ present: false, history: false });
  const [showHistory, setShowHistory] = useState(false);

  const { presentingUserId, togglePresentingUser } =
    useCalculatorActivityContext();

  const { calculator } = useUserCalculator(userId);

  return (
    <Wrapper>
      <StatusWrapper>
        <div id="tooltip-present" className="d-flex">
          <Toggle
            checked={presentingUserId === userId}
            icons={{
              checked: <FontAwesomeIcon icon={faBullhorn} />,
              unchecked: <FontAwesomeIcon icon={faBullhorn} />,
            }}
            onChange={() => togglePresentingUser(userId)}
          />
          <Tooltip
            placement="top"
            isOpen={tooltip.present}
            target="tooltip-present"
            toggle={() => setTooltip({ ...tooltip, present: !tooltip.present })}
          >
            Present this person's calculator to the group
          </Tooltip>
        </div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {calculator?.formula && (
            <FontAwesomeIcon icon={faCalculator} className="mr-1" />
          )}
          {prettyPrintFormula(calculator?.formula)}
        </div>
        <div id="tooltip-history" className="d-flex">
          <Toggle
            checked={showHistory}
            icons={{
              checked: <FontAwesomeIcon icon={faHistory} />,
              unchecked: <FontAwesomeIcon icon={faHistory} />,
            }}
            onChange={() => setShowHistory(!showHistory)}
          />
          <Tooltip
            placement="top"
            isOpen={tooltip.history}
            target="tooltip-history"
            toggle={() => setTooltip({ ...tooltip, history: !tooltip.history })}
          >
            See the calculator history of this person
          </Tooltip>
        </div>
      </StatusWrapper>
      <AnimatePresence>
        {showHistory && (
          <motion.div
            className="d-flex"
            style={{ overflow: "hidden" }}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
          >
            <History userId={userId} style={{ width: "100%" }} />
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StatusWrapper = styled.div`
  background: #3f434a;

  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;

  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
`;
