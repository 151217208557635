import { colors, constants } from "utils";
import { Alert } from "react-bootstrap";
import { useState } from "react";
export const StudentLoginCard = () => {
  // Can't use regular dismissable Alert here as there is a custom button in the design
  const [show, setShow] = useState(true);
  return (
    <div>
      {show && <Alert style={{
        backgroundColor: colors.backGroundSecondary,
        border: "none",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginBottom: 0,
        padding: "20px 16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <p style={{
            color: colors.nightlyWoods900,
            fontSize: "17px",
            fontWeight: 600,
            lineHeight: "24px" }}>{constants.STUDENT_LOGIN_CARD_TITLE}</p>
          <p style={{
            color: colors.nightlyWoods600,
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "20px" }}>{constants.STUDENT_LOGIN_CARD_MESSAGE}</p>
        </div>
        <div style={{
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "white",
          borderRadius: "6px",
          display: "flex",
          gap: "12px",
          padding: "8px 12px" }}>
          <a href={process.env.REACT_APP_OKOLABS_APP_URL}
             style={{
               color: colors.nightlyWoods900,
               display: "flex",
               fontSize: "17px",
               fontWeight: 600,
               gap: "12px",
               lineHeight: "24px",
               textAlign: "center",
               textDecoration: "none",
               width: "100%" }}
             target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M20.1289 5.56125V5.41035L20.0222 5.51706L13.8038 11.7364C13.6748 11.8654 13.4998 11.9379 13.3173 11.9379C13.1349 11.9379 12.9599 11.8654 12.8309 11.7364C12.7019 11.6074 12.6294 11.4324 12.6294 11.25C12.6294 11.0676 12.7019 10.8926 12.8309 10.7636L12.7867 10.7194L12.8309 10.7636L19.0494 4.54419L19.156 4.4375H19.0052H14.8164C14.6341 4.4375 14.4592 4.36507 14.3303 4.23614C14.2013 4.1072 14.1289 3.93234 14.1289 3.75C14.1289 3.56766 14.2013 3.3928 14.3303 3.26386C14.4592 3.13493 14.6341 3.0625 14.8164 3.0625H20.8164C20.9987 3.0625 21.1736 3.13493 21.3025 3.26386C21.4315 3.3928 21.5039 3.56766 21.5039 3.75V9.75C21.5039 9.93234 21.4315 10.1072 21.3025 10.2361C21.1736 10.3651 20.9987 10.4375 20.8164 10.4375C20.6341 10.4375 20.4592 10.3651 20.3303 10.2361C20.2013 10.1072 20.1289 9.93234 20.1289 9.75V5.56125ZM17.0664 19.5625H17.1289V19.5V12.75C17.1289 12.5677 17.2013 12.3928 17.3303 12.2639C17.4592 12.1349 17.6341 12.0625 17.8164 12.0625C17.9987 12.0625 18.1736 12.1349 18.3025 12.2639C18.4315 12.3928 18.5039 12.5677 18.5039 12.75V19.5C18.5039 19.8812 18.3525 20.2469 18.0829 20.5165C17.8133 20.786 17.4477 20.9375 17.0664 20.9375H5.06641C4.68516 20.9375 4.31952 20.786 4.04994 20.5165C3.78036 20.2469 3.62891 19.8812 3.62891 19.5V7.5C3.62891 7.11875 3.78036 6.75312 4.04994 6.48353C4.31952 6.21395 4.68516 6.0625 5.06641 6.0625H11.8164C11.9987 6.0625 12.1736 6.13493 12.3025 6.26386C12.4315 6.3928 12.5039 6.56766 12.5039 6.75C12.5039 6.93234 12.4315 7.1072 12.3025 7.23614C12.1736 7.36507 11.9987 7.4375 11.8164 7.4375H5.06641H5.00391V7.5V19.5V19.5625H5.06641H17.0664Z"
                    fill="#002C18"
                    stroke="#002715"
                    strokeWidth="0.125"/>
            </svg>
            {process.env.REACT_APP_OKOLABS_APP_URL.substring(8)}
          </a>
        </div>
        <button className="student-login-card-close-button" onClick={() => setShow(false)}>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="x-close">
              <path
                id="Icon"
                d="M15.5664 5L5.56641 15M5.56641 5L15.5664 15"
                stroke="#879EB3"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
            </g>
          </svg>
        </button>
      </Alert>}
    </div>
  );
};
