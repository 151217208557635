import { useParams } from "react-router-dom";
import { useFirebase } from "hooks/useFirebase";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";

export const useFirebaseRef = () => {
  const firebase = useFirebase();
  const { sessionId } = useParams();

  return !sessionId
    ? null
    : firebase.firestore().doc(`oko-server/${sessionId}`);
};

export const useOkoLesson = () => {
  const firebase = useFirebase();
  const ref = useFirebaseRef();
  const { authUserData } = useAuthUserDataContext();

  const [lesson, loading, error] = useDocumentData(ref, { idField: "id" });

  const update = (data) => {
    return ref.set(data, { merge: true });
  };

  const say = ({ phrase, useLocal = false }) => {
    update({
      say: {
        id: Math.random().toString(36).slice(-6),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        creatorId: authUserData.id,
        phrase,
        useLocal,
      },
    });
  };

  let [events] = useCollectionData(
    ref?.collection("events")?.orderBy("createdAt", "desc")?.limit(20),
    {
      idField: "id",
    }
  );

  const addEvent = (data, id) => {
    if (!id) id = "i" + Math.random().toString(36).substr(2, 9);

    return ref?.collection("events")?.add({
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  return { events, lesson, loading, error, addEvent, update, say };
};
