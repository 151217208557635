import styled from "styled-components";
import { Participant } from "components/pages/Session/shared/Participant/Participant";
import { OverlayGestureState } from "./OverlayGestureState";
import { useOkoSessionContext } from "hooks/useOkoSession/useOkoSession";

export const GestureVideo = ({
  participant,
  isLocalParticipant,
  isSpectator,
  disable = false,
}) => {
  const {
    calculatorSession: { isAllowingGesture },
  } = useOkoSessionContext();

  return (
    <Wrapper key={participant.sid}>
      <Participant
        participant={participant}
        isLocalParticipant={isLocalParticipant}
        isSpectator={isSpectator}
      />
      {!disable && isAllowingGesture && (
        <OverlayGestureState participant={participant} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
