import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

export const Modal = ({ children, open = false, onClose = () => {} }) => {
  return (
    <AnimatePresence>
      {open && (
        <>
          <Blackout
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "linear", duration: 0.1 }}
          ></Blackout>
          <Container className="fullscreen">
            <Content
              initial={{ y: 200 }}
              animate={{ y: 0 }}
              exit={{ y: 200 }}
              transition={{ ease: "linear", duration: 0.1 }}
            >
              {children}
            </Content>
          </Container>
        </>
      )}
    </AnimatePresence>
  );
};

const Blackout = styled(motion.div)`
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.zOrder.modal};
`;

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.theme.zOrder.modal};
`;

const Content = styled(motion.div)`
  display: flex;

  flex-direction: column;
  min-width: 300px;

  margin: 8px;
  pointer-events: auto;
  background-color: #fff;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;

  padding: 16px;
`;
