import { useState } from "react";
import { texts } from "config/texts";

import {
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  Card,
  CardBlock,
} from "@bootstrap-styled/v4";
import { Toggle } from "components/shared/Toggle/Toggle";
import { useActivity } from "hooks/useActivity/useActivity";

const SettingForm = ({ activity, update, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    title:
      activity?.title === undefined
        ? texts?.waitingRoomDefaultTitle
        : activity?.title,
    subtitle:
      activity?.subtitle === undefined
        ? texts?.waitingRoomDefaultSubtitle
        : activity?.subtitle,
    showParticipants: !!activity?.showParticipants,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    update(formData);
    onSave();
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Card>
      <CardBlock>
        <Form onSubmit={handleSubmit} style={{ minWidth: 500 }}>
          <FormGroup className="form-group">
            <Label htmlFor="titleInput">Title</Label>
            <Input
              type="text"
              className="form-control"
              id="titleInput"
              aria-describedby="titleInput"
              value={formData.title}
              name={"title"}
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup className="form-group">
            <Label htmlFor="subtitleInput">Subtitle</Label>
            <Input
              type="text"
              className="form-control"
              id="subtitleInput"
              aria-describedby="subtitleInput"
              value={formData.subtitle}
              name={"subtitle"}
              onChange={handleChange}
            />
          </FormGroup>

          <div className="mt-2 mb-2">
            <Toggle
              checked={formData.showParticipants}
              onChange={() => {
                setFormData((prevState) => ({
                  ...prevState,
                  showParticipants: !formData.showParticipants,
                }));
              }}
            />
            <span style={{ position: "relative", left: 8, top: -8 }}>
              Students can see a list of other students currently in the session
            </span>
          </div>

          <Button color="primary" type="submit" block className="mt-3">
            Publish
          </Button>
        </Form>
      </CardBlock>
    </Card>
  );
};

export const Settings = ({
  onSave = () => {},
  onClose = () => {},
  activityId,
}) => {
  const { update, activity, loading } = useActivity(activityId);

  return (
    <div className="d-flex">
      {!loading && (
        <SettingForm
          activity={activity}
          update={update}
          onSave={onSave}
          onClose={onClose}
        />
      )}
    </div>
  );
};
