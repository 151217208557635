import { useState, useEffect, createContext } from "react";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { useFirebase } from "hooks/useFirebase";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";

export const useFirebaseRef = () => {
  const firebase = useFirebase();
  return firebase.database().ref("okoServer");
};

export const useGestureState = () => {
  const firebaseRef = useFirebaseRef();
  const [participantGestures] = useState({});

  const [singleHandGesture, setSingleHandGesture] = useState(null);

  const {
    room: { localParticipant },
  } = useVideoContext();
  //write current value to store
  useDataTrack((e) => {
    const participantId = e?.data?.oko_user_id;

    if (!localParticipant.userId || localParticipant.userId !== participantId) return;
    if (
      e?.data?.gesture_type !== "GazeDirection" &&
      e?.data?.gesture_type !== "SingleHandAndFace" &&
      e?.data?.detection?.uuid !== singleHandGesture?.detection?.uuid
    ) {
      setSingleHandGesture(e.data);
    }
  });

  useEffect(() => {
    if (!localParticipant?.sid) return;

    firebaseRef.child(localParticipant.sid).set(singleHandGesture);
  }, [singleHandGesture, firebaseRef, localParticipant]);

  return { participantGestures };
};

const Context = createContext();

export const GestureStateProvider = ({ children }) => {
  const value = useGestureState();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
