import React from "react";
import { SELECTED_AUDIO_INPUT_KEY } from "config/livekit";
import useDevices from "hooks/livekit/useDevices/useDevices";
import useMediaStreamTrack from "hooks/livekit/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const localAudioTrack = localTracks.find((track) => track.kind === "audio");
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      Audio Input
      {audioInputDevices.length > 1 ? (
        <select
          onChange={(e) => replaceTrack(e.target.value)}
          value={localAudioInputDeviceId || ""}
          variant="outlined"
        >
          {audioInputDevices.map((device) => (
            <option value={device.deviceId} key={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
      ) : (
        <>{localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}</>
      )}
    </div>
  );
}
