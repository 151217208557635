import styled from "styled-components";

export const Header = styled.div`
  color: #b5b5b5;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.625;
  font-family: ${(props) => props.theme["$headings-font-family"]};
  margin-top: 0.6 rem;
`;
