import React from "react";
import { colors } from "utils";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const GroupCodeCardItem = ({
                                    name,
                                    options,
                                    value,
                                    setSelectedNode}) => {
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, disabled, onClick }, ref) => (
    <button
      style={{
        alignItems: "center",
        backgroundColor: disabled ? colors.gray100 : "white",
        border: "none",
        borderRadius: "4px",
        color: disabled ? colors.gray200 : colors.nightlyWoods500,
        display: "flex",
        fontFamily: "revert",
        fontSize: "16px",
        fontWeight: "700",
        gap: "10px",
        padding: "8px 12px"}}
      href=""
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className={children.length < 25 ? "group-code-item" : "group-code-item-shift" }>
        {children}
      </div>
      <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
    </button>
  ));

  const handleChange = (option) => {
    setSelectedNode({ name: name, value: option.value, label: option.label });
  };
  const dropDownDisabled = !options || options.length < 1;

  return (
    <div style={{
      alignItems: "center",
      display: "flex",
      color: colors.nightlyWoods950,
      flexDirection: "row",
      gap: "8px",
      height: "40px",
      padding: "4px 0",
      width: "387px" }}>
      <p style={{ fontFamily: "system-ui", width: "125px" }}>{name}</p>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} disabled={dropDownDisabled} id="dropdown-basic">
          {value}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((option, index) => <Dropdown.Item key={index} onClick={() => handleChange(option)}>{option.label}</Dropdown.Item> )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
