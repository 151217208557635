import { CountdownCircleTimer } from "react-countdown-circle-timer";

export const Timer = ({ timeRemaining }) => (
  <CountdownCircleTimer
    isPlaying
    size={32}
    duration={timeRemaining}
    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
    colorsTime={[7, 5, 2, 0]}
    strokeWidth={8}
    strokeLinecap="butt"
    rotation="clockwise"
  ></CountdownCircleTimer>
);
