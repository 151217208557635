import styled from "styled-components";
import { Button } from "./Button/Button";
import { Output } from "./Output";

export const CalculatorRender = ({ buttonIds, bannedButtonIds, formula, clear, onButtonClick, buttonAnimTrigger }) => {
  return (
    <Wrapper>
      <Output formula={formula} onClearClick={clear} />
      <Buttons>
        {buttonIds.map((buttonId) => (
          <Button
            key={buttonId}
            id={buttonId}
            banned={bannedButtonIds.includes(buttonId)}
            onClick={onButtonClick}
            animationTrigger={buttonAnimTrigger}
          ></Button>
        ))}
      </Buttons>
    </Wrapper>
  );
};

const buttonSize = "64px";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  background: rgb(253, 253, 253);
  box-shadow: rgba(59, 65, 94, 0.1) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  border-radius: 5px;
  padding: 16px;
`;

const Buttons = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, ${buttonSize});
  grid-template-rows: repeat(4, ${buttonSize});
`;
