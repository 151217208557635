import { useState } from "react";
import { useContext, createContext } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { firestore } from "hooks/useFirebase";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";

export const getRef = (id) => {
  return firestore.doc(`sessions/${id}`);
};

export const useFirebaseRef = (sid) => {
  const { sessionId } = useParams();
  const id = sessionId || sid;
  return !id ? null : getRef(id);
};

export const useSession = (sid) => {
  const [sessionId, setSessionId] = useState(sid);
  const [groupCode, setGroupCode] = useState();
  const [isStart, setIsStart] = useState();
  const [isStartObserving, setIsStartObserving] = useState();
  const [isCelebrating, setIsCelebrating] = useState();
  const { authUserData } = useAuthUserData();
  const ref = useFirebaseRef(sessionId);
  const [session, loading, error] = useDocumentData(ref, { idField: "id" });
  const update = (data) => {
    ref.set(data, { merge: true });
  };
  const remove = () => {
    ref.delete();
  };
  const userIsSessionAdmin = session?.creatorId === authUserData?.id;
  const url = "/";
  const activityId = session?.activityId || "waiting-room";
  return {
    isStart,
    setIsStart,
    isStartObserving,
    setIsStartObserving,
    isCelebrating,
    setIsCelebrating,
    setSessionId,
    setGroupCode,
    groupCode,
    session,
    activityId,
    ref,
    userIsSessionAdmin,
    code: session?.code,
    url,
    update,
    loading,
    error,
    remove,
  };
};

const Context = createContext(
  // Default value is needed when the context is called by the component that is not under respective context provider.
  { isCelebrating: false, setIsCelebrating: () => {} }
);

export const SessionProvider = ({ children }) => {
  const value = useSession();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSessionContext = () => {
  return useContext(Context);
};
