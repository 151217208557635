import { useUserCalculator } from "hooks/activities/calculator/useUserCalculator/useUserCalculator";
import { useCalculatorActivityContext } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";
import { usePresentingUser } from "hooks/activities/calculator/usePresentingUser/usePresentingUser";
import { useHistory } from "hooks/activities/calculator/useHistory/useHistory";
import { evaluateMathExpression } from "utils";
import { useOkoSessionContext } from "hooks/useOkoSession/useOkoSession";
import { useMembers } from "hooks/livekit/useMembers/useMembers";

export const useCalculator = () => {
  const { goal, instructions, bannedButtonIds } =
    useCalculatorActivityContext();

  const {
    calculatorSession: { calculatorResponse },
  } = useOkoSessionContext();

  const { presentingUserId, localUserId } = usePresentingUser();
  const { add: addToHistory } = useHistory(presentingUserId || localUserId);
  const { calculator, update } = useUserCalculator(presentingUserId);

  const formula = calculator?.formula || "";

  const set = (value) => {
    update({ formula: value });
  };

  const push = (buttonId) => {
    let value = formula + buttonId;
    if (buttonId === "|") value = value.slice(0, -2);
    update({ formula: value });
    sendCalculatorResponse({ formula: value, partial: true });
  };

  const sendCalculatorResponse = ({ formula, partial }) => {
    const answer = evaluateMathExpression(formula);

    if (presentingUserId) {
      calculatorResponse({
        participantId: localUserId,
        formula,
        answer,
        partial,
      });
    }

    return answer;
  };

  const submit = () => {
    // optionally submit value to override existing formula
    if (formula === "") return false;

    addToHistory({
      goal,
      formula,
    });

    const answer = sendCalculatorResponse({ formula, partial: false });

    clear();
    return answer === goal;
  };

  const clear = () => {
    update({ formula: "" });
  };

  return {
    calculator,
    formula,
    goal,
    instructions,
    bannedButtonIds,
    set,
    push,
    submit,
    clear,
  };
};
