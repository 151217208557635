import React, { useEffect, useState } from "react";
import { Rocket } from "./Rocket/Rocket";
import { Confetti } from "./Confetti/Confetti";
import { Fireworks } from "./Fireworks/Fireworks";

export const Celebration = () => {
  const [isRocket, setIsRocket] = useState(false);
  const [isConfetti, setIsConfetti] = useState(false);
  const [isFireworks, setIsFireworks] = useState(false);

  const celebrate = () => {
    const randomValue = Math.floor(Math.random() * 3) + 1; // Generate a random number between 1 and 3
    if (randomValue === 1) {
      setIsRocket(true);
    } else if (randomValue === 2) {
      setIsConfetti(true);
    } else {
      setIsFireworks(true);
    }
  };

  useEffect(() => {
    celebrate();
    return () => {
      setIsRocket(false);
      setIsConfetti(false);
      setIsFireworks(false);
    };
  }, []);

  return (
    <>
      {isRocket && <Rocket />}
      {isFireworks && <Fireworks />}
      <canvas id="canvas" style={{ position: "absolute" }}>
        {isConfetti && <Confetti />}
      </canvas>
    </>
  );
};
