import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "hooks/useFirebase";

export const getRef = (userId) => {
  return firestore.doc(`users/${userId}`);
};

export const useUser = (userId) => {
  const ref = getRef(userId);
  const [user, loading, error] = useDocumentData(userId ? ref : null, {
    idField: "id",
  });
  const update = (data) => {
    ref.set(data, { merge: true });
  };
  return { user, loading, error, update };
};
