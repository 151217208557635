import { Home } from "components/pages/Home/Home";
import { Session } from "components/pages/Session/Session";
import { NotFound } from "components/pages/NotFound/NotFound";
import { Login } from "components/pages/Home/Login";
import {
  MultipleChoiceQuestionRender,
} from "components/pages/Session/Activity/activities/MultipleChoiceActivity/Edit/MultipleChoiceQuestionRender";
import {
  MultipleChoiceQuestionView
} from "components/pages/Session/Activity/activities/MultipleChoiceActivity/Edit/MultipleChoiceQuestionView";
import {
  CrazyCalculatorQuestionRender
} from "components/pages/Session/Activity/activities/CalculatorActivity/Edit/CrazyCalculatorQuestionRender";
import {
  CrazyCalculatorQuestionView
} from "components/pages/Session/Activity/activities/CalculatorActivity/Edit/CrazyCalculatorQuestionView";
import { GroupCodeView } from "components/pages/Educator/Dashboard/GroupCodeView";
import { SidebarView } from "components/SidebarView";
import { colors, constants } from "utils";
import { SessionHistory } from "components/pages/Educator/Session/SessionHistory";
import { SettingsModal } from "components/pages/Educator/SignUp/SettingsModal";
import { Observe } from "components/pages/Session/Observe/Observe";

export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    component: Home,
  },
  {
    name: "Session",
    path: "/session/:sessionId",
    component: Session,
  },
  {
    path: "/educator/signup",
    component: () => <Login isSignUp />,
  },
  {
    path: constants.EDUCATOR_SETTINGS_PATH,
    name: constants.EDUCATOR_SETTINGS_ROUTE_NAME,
    component: () => <SettingsModal isOpen isOnboarding setIsOpen={()=>{}} />,
  },
  {
    path: constants.EDUCATOR_LOGIN_PATH,
    name: constants.EDUCATOR_LOGIN_ROUTE_NAME,
    component: () => <Login redirectPath={constants.EDUCATOR_ROUTE_NAME}/>,
  },
  {
    path: constants.EDUCATOR_PATH,
    name: constants.EDUCATOR_ROUTE_NAME,
    component: () => <>
      <SidebarView viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, paddingTop: "24px" }}>
          <GroupCodeView />
        </div>} headerTitle={constants.DASHBOARD}>
      </SidebarView>
    </>,
  },
  {
    path: constants.SESSION_HISTORY_PATH,
    name: constants.SESSION_HISTORY_ROUTE_NAME,
    component: () => <>
      <SidebarView viewComponent={
        <div style={{ backgroundColor: colors.milkGlass200, padding: "24px" }}>
          <SessionHistory/>
        </div>} headerTitle={constants.SESSION_HISTORY}>
      </SidebarView>
    </>,
  },
  {
    path: "/admin/consensus/questions/render",
    name: "AdminConsensusQuestionsRender",
    component: MultipleChoiceQuestionRender,
  },
  {
    path: "/admin/consensus/questions/view",
    name: "AdminConsensusQuestionsView",
    component: MultipleChoiceQuestionView,
  },
  {
    path: "/admin/crazycalculator/questions/render",
    name: "AdminCrazyCalculatorQuestionsRender",
    component: CrazyCalculatorQuestionRender,
  },
  {
    path: "/admin/crazycalculator/questions/view",
    name: "AdminCrazyCalculatorQuestionsView",
    component: CrazyCalculatorQuestionView,
  },
  {
    path: "/observe/:groupCode",
    name: "Observe",
    component: Observe,
  },
  {
    component: NotFound,
  },
];
