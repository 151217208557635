import { RoomOptions, VideoPresets } from "livekit-client";
import { isMobile } from "utils";

/**
 * LiveKit Room connection options: {@link RoomOptions}
 * or {@link https://docs.livekit.io/client-sdk-js/interfaces/RoomOptions.html}
 */
export default function useRoomOptions() {
  const roomOptions = {
    publishDefaults: {
      videoEncoding: VideoPresets.h720.encoding
    }
  };

  if (isMobile) {
    roomOptions.publishDefaults.videoEncoding = VideoPresets.h540.encoding;
  }

  return roomOptions;
};
