import { Standard } from "./Standard";
import { H6 } from "@bootstrap-styled/v4";
import React from "react";

export const Standards = ({ standards, setStandards }) => {

  return (
    <div>
      <H6>Standards</H6>
      {standards && standards.map((standard, index) => (
        <Standard index={index}
                  standards={standards}
                  setStandards={setStandards}
        />
      ))}
    </div>
  );
};
