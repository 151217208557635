import React, { createContext } from "react";

import { SelectedParticipantProvider } from "./useSelectedParticipant/useSelectedParticipant";
import AttachVisibilityHandler from "./AttachVisibilityHandler/AttachVisibilityHandler";
import useHandleRoomDisconnectionErrors from "./useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors";
import useHandleOnDisconnect from "./useHandleOnDisconnect/useHandleOnDisconnect";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import useLocalTracks from "./useLocalTracks/useLocalTracks";
import useRoom from "./useRoom/useRoom";
import useScreenShareToggle from "./useScreenShareToggle/useScreenShareToggle";
import useActiveAudioSinkId from "./useActiveAudioSinkId/useActiveAudioSinkId";
import { useAppStateContext } from "../../useAppState/useAppState";

export const LiveKitProgrammableVideoContext = createContext(null);

/*
 *  The hooks used by the Provider component are different from the hooks found in the 'hooks/livekit' directory. The hooks
 *  in the 'livekit/hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'useLiveKitProgrammableVideo/' directory are intended to be used by the Provider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export function LiveKitProgrammableVideoProvider({
  connectionOptions,
  roomOptions,
  children,
  onError = () => {},
  onDisconnect = () => {},
}) {
  const onErrorCallback = (error) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  const { isSpectator } = useAppStateContext();

  const {
    localTracks,
    isAcquiringLocalTracks,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect } = useRoom(
    isSpectator ? [] : localTracks,
    onErrorCallback,
    connectionOptions,
    roomOptions
  );

  const [activeAudioSinkId, setActiveAudioSinkId] = useActiveAudioSinkId();

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError
  );

  getAudioAndVideoTracks().catch((error) => {
    console.log("Error acquiring local media:");
    console.dir(error);
  });

  return (
    <LiveKitProgrammableVideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        connect,
        isAcquiringLocalTracks,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
        activeAudioSinkId,
        setActiveAudioSinkId,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      <AttachVisibilityHandler />
    </LiveKitProgrammableVideoContext.Provider>
  );
}
