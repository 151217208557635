import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MenuButton = ({ onClick = () => {}, style = {}, icon }) => {
  return (
    <Wrapper onClick={onClick} style={style}>
      <FontAwesomeIcon icon={icon} />
    </Wrapper>
  );
};

const Wrapper = styled.button`
  z-index: ${(props) => props.theme.zOrder.dashboardMenuButton};
  border: solid 4px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 1000px 0px #607385 inset;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100pc;
  font-size: 28px;
  width: 48px;
  height: 48px;
  padding: 8px;
  cursor: pointer;
  outline: none !important;
  svg {
    filter: drop-shadow(0 1px 0 #073eab);
  }
`;
