import { useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { useGesture } from "hooks/useGesture/useGesture";

const faHand = {
  prefix: "fac",
  iconName: "hand",
  icon: [
    135.46,
    135.46,
    [],
    null,
    "M57.367 129.06c-11.83-4.013-19.203-14.747-23.789-25.036C29.22 93.968 20.045 67.903 20.045 65.58c0-4.222 2.16-6.789 6.38-7.58 5.543-1.04 7.78 1.22 13.47 13.608 2.544 5.54 4.8 9.9 5.011 9.69.212-.212-1.417-12.892-3.62-28.178-2.202-15.286-4.004-29.342-4.004-31.235 0-8.054 9.954-10.903 13.97-3.998 1.109 1.906 3.72 11.99 5.801 22.407l3.786 18.942.288-24.706c.274-23.554.4-24.8 2.712-26.717 3.004-2.49 7.734-2.585 11.108-.222 2.501 1.752 2.556 2.306 2.638 26.717l.085 24.928 3.208-16.088c1.765-8.848 3.565-18.055 4.002-20.46.995-5.487 4.268-8.842 8.627-8.842 7.822 0 8.732 6.592 4.126 29.877-3.644 18.422-3.512 20.114.628 8.044 3.564-10.39 7.21-14.939 11.972-14.939 1.5 0 3.602.875 4.672 1.945 3.002 3.002-.118 13.656-5.64 28.367-7.617 15.295-2.188 30.593-8.455 43.506-7.636 15.561-27.234 24.228-43.442 18.414z",
  ],
};

const handSymbolMap = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: faHand,
  thumbs_up: faThumbsUp,
  thumbs_down: faThumbsDown,
};

export const OverlayGestureState = ({ participant }) => {
  const wrapperRef = useRef(null);
  const fontSize = wrapperRef?.current?.parentNode?.offsetHeight / 4;
  const { gesture } = useGesture(participant);
  const handSymbol = handSymbolMap[gesture?.detection?.gesture.handed_value];

  return (
    <div ref={wrapperRef}>
      <AnimatePresence>
        {handSymbol && (
          <Wrapper
            key={handSymbol}
            initial={{ opacity: 0, rotate: 100, scale: 0.8 }}
            animate={{ opacity: 1, rotate: 0, scale: 1 }}
            exit={{
              opacity: 0,
              rotate: -100,
              scale: 0.8,
              transition: { rotate: { duration: 0.8 } },
            }}
          >
            <FontAwesomeIcon color="#293042" icon={faCertificate} />
            <div style={{ fontSize }}>
              {handSymbol.icon ? (
                <FontAwesomeIcon icon={handSymbol} />
              ) : (
                handSymbol
              )}
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </div>
  );
};

const Wrapper = styled(motion.div)`
  position: absolute;
  color: white;

  right: 26px;
  bottom: 26px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 25%;
  padding-bottom: 25%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 300%;
    font-family: "Rubik", sans-serif;
  }

  > svg {
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.9));
  }
`;
