import "./dashboard.css";
import { constants, getActivityName } from "utils";
import { Badge } from "../../../shared/Badge";
import { GroupCodeButton } from "./GroupCodeButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";
import { getNodeByStandardPrefix } from "utils/standards";

export const GroupCodeCardHeader = ({ activity, groupCodeTitle, prefix, activeSessionQty, standards }) => {
  const getTooltip = () => {
    const node = getNodeByStandardPrefix(standards, prefix);
    return node.standard?.name ?? node.cluster?.name ?? node.domain?.name ?? `Grade: ${node.grade}`;
  }

  const getPrefixElement =
    <div style={{ display: "flex", flexDirection: "row" }}>
      <p className="group-code-card-header">
        {groupCodeTitle + ": " + getActivityName(activity)}
      </p>
      {activity === constants.CONSENSUS_ENUM && prefix &&
        <OverlayTrigger overlay={<Tooltip placement="top">{getTooltip()}</Tooltip>}>
        <p className="group-code-card-header">&nbsp;({prefix})</p>
        </OverlayTrigger>
      }
    </div>;
  return (
    <div className="group-code-card-header-container">
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {getPrefixElement}
        <Badge description="Active Sessions" number={activeSessionQty}></Badge>
      </div>
      <GroupCodeButton code={groupCodeTitle}></GroupCodeButton>
    </div>
  );
};
