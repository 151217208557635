export const findGradeAndStandardNameByPrefix = (standards, prefix, shouldReturnOnlyStandard) => {
  let standardName = '';
  let grade = '';
  main_loop:
  for (let i = 0; i < standards?.length; i++) {
    if (standards[i].domains) {
      for (let j = 0; j < standards[i]?.domains?.length; j++) {
        if (standards[i].domains[j].clusters) {
          for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
            if (standards[i].domains[j].clusters[k].standards) {
              for (let l = 0; l < standards[i]?.domains[j]?.clusters[k]?.standards?.length; l++) {
                if (standards[i].domains[j].clusters[k].standards[l].code.standard_code === prefix) {
                  standardName = standards[i].domains[j].clusters[k].standards[l].name;
                  grade = standards[i].grade;
                  break main_loop;
                }
              }
            }
          }
        }
      }
    }
  }
  if (standardName && shouldReturnOnlyStandard) {
    return standardName;
  }
  if (standardName && grade) {
    return 'Grade: ' + grade + ', ' + 'Standard: ' + standardName;
  }
  return '';
};

export const findClusterNameByPrefix = (standards, prefix) => {
  let clusterName = '';
  main_loop:
    for (let i = 0; i < standards?.length; i++) {
      if (standards[i].domains) {
        for (let j = 0; j < standards[i]?.domains?.length; j++) {
          if (standards[i].domains[j].clusters) {
            for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
              if (standards[i].domains[j].clusters[k].prefix === prefix) {
                clusterName = standards[i].domains[j].clusters[k].name;
                break main_loop;
              }
            }
          }
        }
      }
    }
  return clusterName;
};

export const getAllPrefixes = (standards) => {
  const allPrefixes = [];
  for (let i = 0; i < standards?.length; i++) {
    if (standards[i].domains) {
      for (let j = 0; j < standards[i]?.domains?.length; j++) {
        if (standards[i].domains[j].clusters) {
          allPrefixes.push(standards[i].domains[j].prefix);
          for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
            if (standards[i].domains[j].clusters[k].standards) {
              allPrefixes.push(standards[i].domains[j].clusters[k].prefix);
              for (let l = 0; l < standards[i]?.domains[j]?.clusters[k]?.standards?.length; l++) {
                if (standards[i].domains[j].clusters[k].standards[l].code.standard_code) {
                  allPrefixes.push(standards[i].domains[j].clusters[k].standards[l].code.standard_code);
                }
              }
            }
          }
        }
      }
    }
  }
  return allPrefixes;
};

export const buildSelectOptions = (standards) => {
  const options = [];
  for (let i = 0; i < standards?.length; i++) {
    options.push({"value": standards[i].grade, "label": 'Grade: ' + standards[i].grade});
    if (standards[i].domains) {
      for (let j = 0; j < standards[i]?.domains?.length; j++) {
        if (standards[i].domains[j].clusters) {
          let domainNode = standards[i].domains[j];
          options.push({"value": domainNode.prefix, "label": domainNode.prefix + ': ' + domainNode.name});
          for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
            if (standards[i].domains[j].clusters[k].standards) {
              let clusterNode = standards[i].domains[j].clusters[k];
              options.push({"value": clusterNode.prefix, "label": clusterNode.prefix + ': ' + clusterNode.name});
              for (let l = 0; l < standards[i]?.domains[j]?.clusters[k]?.standards?.length; l++) {
                if (standards[i].domains[j].clusters[k].standards[l].code.standard_code) {
                  let standardNode = standards[i].domains[j].clusters[k].standards[l];
                  options.push({"value": standardNode.code.standard_code,
                    "label": standardNode.code.standard_code + ': ' + standardNode.name});
                }
              }
            }
          }
        }
      }
    }
  }
  return options;
}

export const getGrades = (standards) => {
  const grades = [];
  for (let i = 0; i < standards?.length; i++) {
    grades.push({"value": standards[i].grade, "label": 'Grade: ' + standards[i].grade});
  }
  return grades;
}

export const getDomainsByGradeLevel = (standards, grade) => {
  const domains = [];
  for (let i = 0; i < standards?.length; i++) {
    if (standards[i].grade === grade) {
      for (let j = 0; j < standards[i]?.domains?.length; j++) {
        const domainNode = standards[i].domains[j];
        domains.push({"value": domainNode.prefix, "label": domainNode.name});
      }
    }
  }
  return domains;
}

export const getClustersByDomain = (standards, domain) => {
  const clusters = [];
  for (let i = 0; i < standards?.length; i++) {
    for (let j = 0; j < standards[i]?.domains?.length; j++) {
      if (standards[i].domains[j].prefix === domain) {
        for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
          const clusterNode = standards[i].domains[j].clusters[k];
          clusters.push({"value": clusterNode.prefix, "label": clusterNode.name});
        }
      }
    }
  }
  return clusters;
}

export const getStandardsByCluster = (standards, cluster) => {
  const _standards = [];
  for (let i = 0; i < standards?.length; i++) {
    for (let j = 0; j < standards[i]?.domains?.length; j++) {
      for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
        if (standards[i].domains[j].clusters[k].prefix === cluster) {
          for (let l = 0; l < standards[i]?.domains[j]?.clusters[k]?.standards?.length; l++) {
            let standardNode = standards[i].domains[j].clusters[k].standards[l];
            _standards.push({"value": standardNode.code.standard_code, "label": standardNode.name});
          }
        }
      }
    }
  }
  return _standards;
}

export const getNodeByStandardPrefix = (standards, prefix) => {
  let node = {};
  main_loop:
    for (let i = 0; i < standards?.length; i++) {
      node.grade = standards[i].grade;
      if (prefix === node.grade) {
        node.domain = '';
        node.cluster = '';
        node.standard = '';
        break;
      }
      if (standards[i].domains) {
        for (let j = 0; j < standards[i]?.domains?.length; j++) {
          node.domain = {
            name: standards[i].domains[j].name,
            prefix: standards[i].domains[j].prefix
          };
          if (prefix === node.domain.prefix) {
            node.cluster = '';
            node.standard = '';
            break main_loop;
          }
          if (standards[i].domains[j].clusters) {
            for (let k = 0; k < standards[i]?.domains[j]?.clusters?.length; k++) {
              node.cluster = {
                name: standards[i].domains[j].clusters[k].name,
                prefix: standards[i].domains[j].clusters[k].prefix
              };
              if (prefix === node.cluster.prefix) {
                node.standard = '';
                break main_loop;
              }
              if (standards[i].domains[j].clusters[k].standards) {
                for (let l = 0; l < standards[i]?.domains[j]?.clusters[k]?.standards?.length; l++) {
                  node.standard = {
                    name: standards[i].domains[j].clusters[k].standards[l].name,
                    prefix: standards[i].domains[j].clusters[k].standards[l].code.standard_code
                  };
                  if (prefix === node.standard.prefix) {
                    break main_loop;
                  }
                }
              }
            }
          }
        }
      }
    }
    return node;
};
