import { useEffect, useState } from "react";
import { TrackEvent } from "livekit-client";
export default function useTrack(publication) {
  const [track, setTrack] = useState(publication && publication.track);
  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication && publication.track);
    if (publication) {
      const removeTrack = () => setTrack(null);
      publication.on(TrackEvent.Subscribed, setTrack);
      publication.on(TrackEvent.Unsubscribed, removeTrack);
      return () => {
        publication.off(TrackEvent.Subscribed, setTrack);
        publication.off(TrackEvent.Unsubscribed, removeTrack);
      };
    }
  }, [publication]);

  return track;
}
