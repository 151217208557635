import { useEffect } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";
import { RoomEvent } from "livekit-client";

export const useDataTrack = (callback = () => {}) => {
  const decoder = new TextDecoder();
  const { room } = useVideoContext();
  useEffect(() => {
    const onTrackMessage = (data) => {
      const strData = decoder.decode(data);
      callback(JSON.parse(strData));
    };
    room.on(RoomEvent.DataReceived, onTrackMessage);
    return () => {
      room.off(RoomEvent.DataReceived, onTrackMessage);
    };
  }, [room, callback]);

  return callback;
};
