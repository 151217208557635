import './index.css';
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { App } from "./components/App";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
});

ReactDOM.render(<App />, document.getElementById("root"));
