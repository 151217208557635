import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

export const Banned = ({ icon }) => {
  return (
    <Button>
      <FontAwesomeIcon icon={faBan} size="xs" />
      {icon}
    </Button>
  );
};

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 5px;
  transform-origin: right top;
  user-select: none;
  outline: 1px solid transparent;
  position: relative;
  color: #607385;

  svg {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    color: #721c24;
    opacity: 0.6;
  }
`;
