import "./standard.css"
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Standard = ({ index, standards, setStandards }) => {

  const handleStandardChange = (e) => {
    const standardsCopy = JSON.parse(JSON.stringify(standards));
    standardsCopy[index] = e.target.value;
    setStandards(standardsCopy);
  };

  const deleteStandard = (e) => {
    const standardsCopy = JSON.parse(JSON.stringify(standards));
    standardsCopy.splice(index, 1);
    setStandards(standardsCopy);
  };

  return (
    <div>
      <span>
        <input className="standard margin-right-5" type="text" value={standards[index]} onChange={handleStandardChange}/>
        <button style={{ borderRadius: "100px", borderWidth: "1px" }} value={index} onClick={deleteStandard}>
          <FontAwesomeIcon size="sm" icon={faTrash} />
        </button>
      </span>
    </div>
  );
};
