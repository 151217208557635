import { evaluate } from "mathjs";
import * as Sentry from "@sentry/react";

export const evaluateMathExpression = (expression) => {
  let result = null;

  try {
    result = evaluate(expression);
  } catch (e) {
    Sentry.captureException(e);
  }

  return result;
};

export const prettyPrintFormula = (formula, limit = 40) => {
  if (!formula) return "";

  return formula
    .replaceAll("*", "×")
    .replaceAll("-", "−") // longer minus sign
    .replaceAll("/", "÷")
    .slice(limit * -1);
};
