import * as Sentry from "@sentry/react";
import { H3 } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { request } from "../../../../../../../hooks/useOkoApi/useOkoApi";
import { CalculatorRender } from "../Calculator/CalculatorRender";
import { useButtonIds } from "../../../../../../../hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { EditCrazyCalculatorQuestionModal } from "./EditCrazyCalculatorQuestionModal";
import { buttonIdsToStr } from "utils/challenge";

export const CrazyCalculatorQuestionRender = () => {
  const buttonIds = useButtonIds();
  const [questionsData, setQuestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [challengeToEdit, setChallengeToEdit] = useState();

  const getQuestionsData = async () => {
    try {
      setIsLoading(true);
      const questionsData = await request(
        'questions/fetch/crazycalculator?fetch_inactive=true', {
          method: "GET",
        });
      setQuestionsData(questionsData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  useEffect( () => {
    getQuestionsData();
  },[]);

  const onEdit = (challenge) => {
    setChallengeToEdit(challenge);
    setIsModalOpen(true);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%"}}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      {!isLoading && <>
        {questionsData.map((challenge, index) => (
          <div style={{ alignSelf: "center", display: "flex", marginBottom: "50px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <H3
                className="mb-2 text-center"
                style={{ textShadow: "0px 1px 3px #fff" }}
              >Try to make the number {challenge.target_answer}.</H3>
              <CalculatorRender
                key={index}
                buttonIds={buttonIdsToStr(buttonIds)}
                bannedButtonIds={challenge.broken_keys}
                formula={""}
                clear={() => {}}
                onButtonClick={() => {}}
                buttonAnimTrigger={{}}
              />
            </div>
            <div style={{ alignSelf: "center" }}>
              <button style={{
                borderRadius: "100px",
                borderWidth: "1px",
                marginLeft: "18px",
                width: "75px",
                height: "75px",
                alignSelf: "center"
              }} onClick={() => onEdit(challenge)}>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit
              </button>
            </div>
            <br/>
          </div>
        ))}
      </>
      }
      {challengeToEdit && <EditCrazyCalculatorQuestionModal challenge={challengeToEdit}
                                                            isModalOpen={isModalOpen}
                                                            modalTitle="Edit Challenge"
                                                            setIsModalOpen={setIsModalOpen}/>
      }
    </div>
  );
};
