import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Track } from "livekit-client";
import styled from "styled-components";
import usePublications from "hooks/livekit/usePublications/usePublications";
import { Overlay } from "../shared/Participant/Overlay/Overlay";
import { VideoTrack } from "../shared/VideoTrack/VideoTrack";
import { AudioTrack } from "../shared/AudioTrack/AudioTrack";

export const ObserverParticipant = ({ participant, isAudioMuted, isVideoMuted }) => {
  const publications = usePublications(participant);
  const audioPublications = publications.filter((p) => (
    p.kind.includes(Track.Kind.Audio)
  ));
  const videoPublications = publications.filter((p) => (
    p.source.includes(Track.Source.Camera)
  ));
  const screenSharePublications = publications.filter((p) => (
    p.source.includes(Track.Source.ScreenShare)
  ));
  return (
    <>
      {videoPublications.map((p) => (
        <>
          <AwayIcon icon={faUserAstronaut} />
          <VideoTrack
            key={p.trackSid}
            track={p.track}
            disabled={isVideoMuted}
          />
          <Overlay participant={participant} isAudioMuted={isAudioMuted} isVideoMuted={isVideoMuted} isSpectator />
        </>
      ))}
      {screenSharePublications.map((p) => (
        <>
          <VideoTrack
            key={p.trackSid}
            track={p.track}
          />
          <Overlay
            participant={participant}
            isAudioMuted={isAudioMuted}
            isVideoMuted={isVideoMuted}
            isSpectator
            isScreenShare />
        </>
      ))}
      {audioPublications.map((p) => (
        <AudioTrack
          key={p.trackSid}
          track={p.track} />
      ))}
    </>
  );
};

const AwayIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 50%;
  bottom: 50%;
  color: white;
  width: 50% !important;
  height: 50%;
  transform: translate(-50%, 50%);
`;
