import styled from "styled-components";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";

export const Key = ({ id, icon, onClick = () => {}, animationTrigger }) => {
  const [animate, setAnimate] = useState("initial");
  const [triggeredAnimations, setTriggeredAnimations] = useState([]);
  const [animationLock, setAnimationLock] = useState(null);

  const buttonType = Number.isInteger(id)
    ? "number"
    : { "=": "equals", "|": "backspace" }[id] || "operation";

  const variants = useMemo(() => getVariants(buttonType), [buttonType]);

  const triggerAnimation = useCallback(
    (name, target) => {
      if (!name || animationLock || target !== id) return false;

      setAnimate(name);
      const duration = (variants[name]?.transition?.duration || 0.8) * 1000;
      setAnimationLock(true);
      setTimeout(() => {
        setAnimationLock(false);
        setAnimate("initial");
      }, duration);

      return true;
    },
    [animationLock, variants]
  );

  useEffect(() => {
    if (triggeredAnimations.includes(animationTrigger)) return;
    setTriggeredAnimations([...triggeredAnimations, animationTrigger]);

    triggerAnimation(animationTrigger?.name, animationTrigger?.target);
  }, [animationTrigger, triggerAnimation, triggeredAnimations]);

  return (
    <Button
      variants={variants}
      initial={"initial"}
      animate={animate}
      whileTap={!animationLock && variants.whileTap}
      onClick={() => {
        onClick(id);
      }}
    >
      {animateIconMap[animate] || icon}
    </Button>
  );
};

const Button = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transform-origin: right top;
  user-select: none;
  outline: 1px solid transparent;
`;

const getVariants = (buttonType) => {
  return {
    initial: {
      x: 0,
      scale: {
        number: 1,
        operation: 1,
        equals: 1.4,
        backspace: 1,
      }[buttonType],
      background: {
        number: "rgba(239, 242, 247, 1)",
        operation: "rgba(58, 153, 204, 1)",
        equals: "rgba(80, 181, 255, 1)",
        backspace: "rgba(96, 115, 133, 1)",
      }[buttonType],
      border: {
        number: "1px solid rgba(204, 207, 212, 0.4)",
        operation: "1px solid rgba(58, 153, 204, 0.4)",
        equals: "1px solid rgba(45, 146, 220, 0.4)",
        backspace: "1px solid rgba(61, 80, 98, 0.4)",
      }[buttonType],
      color: {
        number: "rgba(96, 115, 133, 1)",
        operation: "rgb(254, 254, 255, 1)",
        equals: "rgba(254, 254, 255, 1)",
        backspace: "rgba(254, 254, 255, 1)",
      }[buttonType],
      boxShadow: {
        number: "rgba(204, 207, 212, 0.4) 0px 3px 0px 0px",
        operation: "rgba(23, 118, 169, 0.4) 0px 3px 0px 0px",
        equals: "rgba(45, 146, 220, 0.4) 0px 3px 0px 0px",
        backspace: "rgba(61, 80, 98, 0.4) 0px 3px 0px 0px",
      }[buttonType],
    },
    whileTap: {
      y: 4,
      boxShadow: {
        number: "rgba(204, 207, 212, 0.4) 0px 0px 0px 0px",
        operation: "rgba(23, 118, 169, 0.4) 0px 0px 0px 0px",
        equals: "rgba(45, 146, 220, 0.4) 0px 0px 0px 0px",
        backspace: "rgba(61, 80, 98, 0.4) 0px 0px 0px 0px",
      }[buttonType],
    },
    whileTapRemote: {
      y: 4,
      color: "rgba(255, 255, 255, 1)",
      background: "rgba(96, 115, 133, 1)",
      boxShadow: {
        number: "rgba(204, 207, 212, 0.4) 0px 0px 0px 0px",
        operation: "rgba(23, 118, 169, 0.4) 0px 0px 0px 0px",
        equals: "rgba(45, 146, 220, 0.4) 0px 0px 0px 0px",
        backspace: "rgba(61, 80, 98, 0.4) 0px 0px 0px 0px",
      }[buttonType],
    },
    shakeWrong: {
      color: "rgba(255, 255, 255, 1)",
      background: "rgba(255, 0, 0, 1)",
      border: "1px solid rgba(255, 0, 0, 0.4)",
      x: [0, -10, 10, -10, 10, -10, 10, -10, 0],
      boxShadow: "rgba(255, 0, 0, 0.4) 0px 0px 0px 0px",
      transition: {
        duration: 0.5,
        ease: "easeIn",
        color: { duration: 0.1, ease: "easeIn" },
        background: { duration: 0.1, ease: "easeIn" },
        border: { duration: 0.1, ease: "easeIn" },
        boxShadow: { duration: 0.1, ease: "easeIn" },
      },
    },
    shakeRight: {
      color: "rgba(255, 255, 255, 1)",
      background: "rgba(0, 255, 0, 1)",
      border: "1px solid rgba(0, 255, 0, 0.4)",
      y: [0, -20, 10, 0],
      boxShadow: "rgba(0, 255, 0, 0.4) 0px 0px 0px 0px",
      transition: {
        duration: 0.4,
        ease: "easeIn",
        color: { duration: 0.1, ease: "easeIn" },
        background: { duration: 0.1, ease: "easeIn" },
        border: { duration: 0.1, ease: "easeIn" },
        boxShadow: { duration: 0.1, ease: "easeIn" },
      },
    },
  };
};

const animateIconMap = {
  shakeRight: <FontAwesomeIcon icon={faThumbsUp} size="xs" />,
  shakeWrong: <FontAwesomeIcon icon={faTimes} size="xs" />,
};
