import styled from "styled-components";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { ActionButton } from "./ActionButton";
import { useAppStateContext } from "hooks/useAppState/useAppState";

export const ToggleSettingsModalButton = () => {
  const {
    modal: { openDeviceSettingsModal },
  } = useAppStateContext();

  return (
    <StyledActionButton
      icon={faCog}
      active={false}
      className="settings"
      tooltip={"Camera Settings"}
      onClick={openDeviceSettingsModal}
    />
  );
};

const StyledActionButton = styled(ActionButton)`
  margin-top: auto;
  background: white;
  color: #607385;
  box-shadow: none;
  margin-bottom: 0;
  font-size: 30px;
  svg {
    filter: none;
  }
`;
