import styled from "styled-components";
import { ActivityButton } from "./ActivityButton";
import { Header } from "./Header";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { activities } from "config/activities";

export const ActivitiesMenu = ({ changeToPanel }) => {
  const {
    activity: currentActivity,
    change: changeActivity,
  } = useSessionActivity();

  return (
    <Wrapper>
      <Header>Activities</Header>
      <ul>
        {activities.map((activity) => (
          <li key={activity.id}>
            <ActivityButton
              activity={activity}
              onPlayClick={(activity) => {
                changeActivity(activity);
                changeToPanel({
                  component: activity.settings,
                  label: activity.label,
                });
              }}
              isActive={currentActivity?.id === activity.id}
              onClick={(activity) => {
                changeToPanel({
                  component: activity.settings,
                  label: activity.label,
                });
              }}
            />
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  //margin-bottom: 30px;
`;
