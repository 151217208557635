import { useEffect } from "react";
import { useAppStateContext } from "hooks/useAppState/useAppState";
import { Session } from "../Session";

export const ObserverView = ({ groupCode }) => {
  const { setGroupCode, setIsSpectator } = useAppStateContext();
  useEffect(async () => {
    setGroupCode(groupCode);
    setIsSpectator(true);
  }, []);

  return <Session />;
};
