export const theme = {
  "$font-family-base": `'Nunito Sans', sans-serif`,
  "$headings-font-family": `'Rubik', sans-serif`,
  "$btn-font-family": `'Rubik', sans-serif`,
  "$grid-gutter-width": "16px",
  "$container-max-widths": {
    sm: "540px",
    md: "760px",
    lg: "968px",
    xl: "1140px",
  },
  zOrder: {
    videoActionBar: 10,
    dashboardMenuButton: 100,
    modal: 200,
  },
  "$list-group-item-padding-x": ".75rem",
  "$list-group-item-padding-y": ".75rem",
};
