import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import useDevices from "hooks/livekit/useDevices/useDevices";

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeAudioSinkId, setActiveAudioSinkId } = useVideoContext();
  const activeOutputLabel = audioOutputDevices.find(
    (device) => device.deviceId === activeAudioSinkId
  )?.label;

  return (
    <div>
      {audioOutputDevices.length > 1 ? (
        <>
          Audio Output
          <select
            onChange={(e) => setActiveAudioSinkId(e.target.value)}
            value={activeAudioSinkId}
            variant="outlined"
          >
            {audioOutputDevices.map((device) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>
          Audio Output
          {activeOutputLabel || "System Default Audio Output"}
        </>
      )}
    </div>
  );
}
