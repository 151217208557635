import { useState } from "react";

export const useModal = () => {
  const [activeModalId, setActiveModalId] = useState(null);

  const isDeviceSettingsModalOpen = activeModalId === "DeviceSettingsModal";
  const openDeviceSettingsModal = () => setActiveModalId("DeviceSettingsModal");
  const closeDeviceSettingsModal = () => setActiveModalId(null);

  const isDebugModalOpen = activeModalId === "DebugModal";
  const openDebugModal = () => setActiveModalId("DebugModal");
  const closeDebugModal = () => setActiveModalId(null);

  return {
    isDeviceSettingsModalOpen,
    openDeviceSettingsModal,
    closeDeviceSettingsModal,

    isDebugModalOpen,
    openDebugModal,
    closeDebugModal,
  };
};
