import styled from "styled-components";

export const Blob = () => {
  return (
    <Container>
      <div className="blob">
        <div className="blob">
          <div className="blob"></div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .blob {
    animation: blobify 2s infinite linear;
    background: linear-gradient(0deg, #5c81ea, #583dcd);
    height: 100%;
    transition: border-radius 1s linear;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blob > .blob {
    animation-direction: reverse;
    animation-duration: 3s;
    width: 80%;
    height: 80%;
  }
  .blob > .blob > .blob {
    animation-direction: forwards;
    animation-duration: 4s;
  }
  .blob > .blob > .blob {
    animation-direction: reverse;
    animation-duration: 5s;
  }

  @keyframes blobify {
    0% {
      border-radius: 85% 100% 85% 100% / 100% 85% 100% 85%;
      transform: rotate(0deg);
    }
    85% {
      border-radius: 100% 85% 100% 85% / 85% 100% 85% 100%;
    }
    100% {
      border-radius: 85% 100% 85% 100% / 100% 85% 100% 85%;
      transform: rotate(-360deg);
    }
  }
`;
