import { useObjectVal } from "react-firebase-hooks/database";
import { useFirebase } from "hooks/useFirebase";

export const useFirebaseRef = (id) => {
  const firebase = useFirebase();
  if (!id) return null;
  return firebase.database().ref("okoServer").child(id);
};

export const useGesture = (participant) => {
  const ref = useFirebaseRef(participant ? participant.sid : null);
  const [gesture, loading, error] = useObjectVal(ref);

  return { gesture, loading, error };
};
