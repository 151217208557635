import { motion, AnimatePresence } from "framer-motion";

import { H3 } from "@bootstrap-styled/v4";
import { useCalculatorActivityContext } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";

export const Instructions = () => {
  const transition = { duration: 0.3 };
  const { instructions } = useCalculatorActivityContext();

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <motion.div
        key={instructions}
        initial={{ opacity: 0, y: -20, transition }}
        animate={{ opacity: 1, y: 0, transition }}
        exit={{ opacity: 0, y: 20, transition }}
      >
        <H3
          className="mb-2 text-center"
          style={{ textShadow: "0px 1px 3px #fff" }}
        >
          {instructions}
        </H3>
      </motion.div>
    </AnimatePresence>
  );
};
