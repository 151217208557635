import { useAppStateContext } from "hooks/useAppState/useAppState";

import AudioInputList from "./AudioInputList/AudioInputList";
import AudioOutputList from "./AudioOutputList/AudioOutputList";
import VideoInputList from "./VideoInputList/VideoInputList";

import { Button } from "@bootstrap-styled/v4";
import { Modal } from "components/shared/Modal/Modal";

export default function DeviceSettingsModal() {
  const {
    modal: { isDeviceSettingsModalOpen, closeDeviceSettingsModal },
  } = useAppStateContext();

  return (
    <Modal open={isDeviceSettingsModalOpen}>
      <VideoInputList />
      <AudioInputList />
      <AudioOutputList />
      <Button onClick={closeDeviceSettingsModal}>Done</Button>
    </Modal>
  );
}
