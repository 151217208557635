import { useParams } from "react-router-dom";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { ObserverView } from "./ObserverView";
import { Login } from "../../Home/Login";

export const Observe = () => {
  const { groupCode } = useParams();
  const { authUserData, loading } = useAuthUserData();
  if (loading) return <></>;
  if (authUserData.isAnonymous) return <Login redirectPath="Observe" redirectParams={{ groupCode: groupCode }} />;
  return <ObserverView groupCode={groupCode} />
};
