import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { config as firebaseConfig } from "config/firebase";

firebase.initializeApp(firebaseConfig);

export const useFirebase = () => {
  return firebase;
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
