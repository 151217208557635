import { useContext, createContext, useState } from "react";
import { useModal } from "./useModal";

export const useAppState = () => {
  const modal = useModal();
  const [displayName, setDisplayName] = useState();
  const [groupCode, setGroupCode] = useState();
  const [sessionAuthType, setSessionAuthType] = useState();
  const [isRecordSessions, setIsRecordSessions] = useState();
  const [isSpectator, setIsSpectator] = useState();
  return {
    modal,
    displayName,
    setDisplayName,
    groupCode,
    setGroupCode,
    sessionAuthType,
    setSessionAuthType,
    isRecordSessions,
    setIsRecordSessions,
    isSpectator,
    setIsSpectator,
  };
};

const Context = createContext();

export const AppStateProvider = ({ children }) => {
  const value = useAppState();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAppStateContext = () => {
  return useContext(Context);
};
