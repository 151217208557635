import styled from "styled-components";
import { Card, Row, Col, H3, Img } from "@bootstrap-styled/v4";
import calcImg from "assets/calc.svg";
import handshakeImg from "assets/handshake1.svg"; //https://www.manypixels.co/gallery
import { useSessionContext } from "hooks/useSession";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { constants } from "utils";

export const IconSelectActivity = () => {
  const { session, update: updateSession } = useSessionContext();
  const { change: changeActivity } = useSessionActivity();

  const activity = {
    choices: [
      {
        id: "2",
        text: constants.CRAZY_CALCULATOR,
        icon: "calculator",
        value: constants.CRAZY_CALCULATOR_ENUM,
      },
      { id: "3", text: constants.LETS_TALK, icon: "handshake", value: constants.CONSENSUS_ENUM },
    ],
  };

  const iconMap = {
    handshake: handshakeImg,
    calculator: calcImg,
  };

  const onClick = async (val) => {
    await request(`session/${session?.id}/select_activity_type`, {
      payload: { activity_type: val },
    });
    updateSession({ sessionActivityType: val });
    changeActivity({ id: "ready-room" });
  };

  return (
    <Wrapper>
      <Row style={{ width: "70vw" }}>
        {activity.choices.map((choice) => (
          <Col key={choice.id}>
            <Choice
              className="p-4 text-center"
              onClick={() => {
                onClick(choice.value);
              }}
            >
              <Img src={iconMap[choice.icon]} />
              <H3 className="mt-4">{choice.text}</H3>
            </Choice>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 82px;
`;

const Choice = styled(Card)`
  cursor: pointer !important;
  border-radius: 12px !important;

  &:hover {
    background-color: #c7ffcd !important;
  }
`;
