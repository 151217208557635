import { useSessionContext } from "hooks/useSession";

export const useParticipantMedia = (participant) => {
  const { session, update: updateSession } = useSessionContext();

  const videoDisabled = Boolean(
    session?.diabledUserMedia?.[participant.userId]?.["video"] ||
      (session?.hideOtherMembersVideo && Array.from(participant.videoTrackPublications.values()).length < 1)
  );
  const audioDisabled = Boolean(
    session?.diabledUserMedia?.[participant.userId]?.["audio"]
  );

  const toggleMedia = (participant, type, value) => {
    updateSession({
      disabledUserMedia: {
        [participant.userId]: { [type]: value },
      },
    });
  };

  const toggleParticipantVideo = () => {
    toggleMedia(participant, "video", !videoDisabled);
  };

  const toggleParticipantAudio = () => {
    toggleMedia(participant, "audio", !audioDisabled);
  };

  return {
    toggleParticipantVideo,
    toggleParticipantAudio,
    videoDisabled,
    audioDisabled,
  };
};
