import styled from "styled-components";

import {
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  Col,
} from "@bootstrap-styled/v4";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { Toggle } from "components/shared/Toggle/Toggle";
import { useButtonIds } from "hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { prettyPrintFormula } from "utils";

export const Calculator = ({ formData, setFormData, onPublish }) => {
  const buttonIds = useButtonIds();

  const onToggle = (e, id) => {
    if (e.target.checked)
      setFormData({
        ...formData,
        bannedButtonIds: [...formData.bannedButtonIds, id],
      });
    else
      setFormData({
        ...formData,
        bannedButtonIds: formData.bannedButtonIds.filter((v) => v !== id),
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onPublish(formData);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (e.target.getAttribute("type") === "checkbox") {
      value = !!e.target.checked;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="mb-2" row>
        <Col>
          <Label className="mb-0" htmlFor="goal">
            Goal Number
          </Label>
          <Input
            type="number"
            className="form-control"
            name="goal"
            value={formData.goal}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <Label className="mb-0" htmlFor="startFormula">
            Start Formula
          </Label>
          <Input
            type="text"
            className="form-control"
            name="startFormula"
            value={formData.startFormula}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <Label className="mb-0" htmlFor="goal">
            Clear History
          </Label>
          <Input
            className="form-control"
            name="clearHistory"
            type="checkbox"
            checked={
              formData?.clearHistory === undefined ||
              formData?.clearHistory === true
            }
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="mb-0" htmlFor="instuctionsInput">
          Instructions
        </Label>
        <Input
          type="text"
          className="form-control"
          name="instructions"
          value={formData.instructions}
          onChange={handleChange}
        />
      </FormGroup>

      <TogglesGrid>
        {buttonIds.map((id) => {
          const isBanned = formData.bannedButtonIds?.includes(id) || false;

          return Number.isInteger(id) || ["-", "+", "/", "*"].includes(id) ? (
            <label key={id} className={isBanned ? "banned" : ""}>
              <span>
                {prettyPrintFormula(id + "")}
                <FontAwesomeIcon icon={faBan} />
              </span>
              <Toggle
                checked={isBanned}
                icons={false}
                onChange={(e) => onToggle(e, id)}
                colors={{ checked: "#AB1919", unchecked: "#4D4D4D" }}
              />
            </label>
          ) : (
            <div key={id}></div>
          );
        })}
      </TogglesGrid>

      <Button color="primary" type="submit" block className="mt-2 mb-2">
        Publish
      </Button>
    </Form>
  );
};

const TogglesGrid = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(4, 64px);
  grid-template-rows: repeat(4, 64px);
  justify-content: center;
  label {
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: center;
    font-size: 26px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    background: #eff2f7;
    color: #3f434a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
    border: 1px solid #c6c6c666;

    span {
      height: 34px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg[data-icon="ban"] {
      display: none;
      position: absolute;
      color: #bf9393;
      opacity: 0.5;
      width: 32px;
      height: 32px;
    }

    &.banned {
      background: transparent;
      border: 1px solid transparent;
      svg[data-icon="ban"] {
        display: flex;
      }
    }
  }
`;
