import { Footer } from "@bootstrap-styled/v4";

export const OKOFooter = () => {
  return (
    <Footer
      className="text-center p-3 text-small text-muted position-fixed w-100"
      style={{ bottom: 0, opacity: 0.4 }}
    >
      © {new Date().getFullYear()} Oko Labs, Inc. All right reserved.
    </Footer>
    );
};
