import { useState } from "react";

export const useFormData = ({ initial = {} }) => {
  const [formData, setFormData] = useState(initial);

  const onChange = (e) => {
    let { name, value } = e.target;

    if (e.target.getAttribute("type") === "checkbox") {
      value = !!e.target.checked;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return { formData, onChange, setFormData };
};
