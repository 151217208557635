import { useSessionContext } from "hooks/useSession";
import { activities } from "config/activities";

export const useSessionActivity = () => {
  const {
    activityId,
    update: updateSession,
    userIsSessionAdmin,
  } = useSessionContext();

  const activity = activities.find((a) => a.id === activityId);

  const change = (activity) => {
    updateSession({ activityId: activity.id });
  };

  const userCanChangeActivity = userIsSessionAdmin;

  return { activity, change, userCanChangeActivity, activityId };
};
