import styled from "styled-components";
import { Button } from "@bootstrap-styled/v4";
import { Toggle } from "components/shared/Toggle/Toggle";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";

export const ShareScreenToggle = () => {
  const { toggleScreenShare, isSharingScreen } = useVideoContext();
  return (
    <StyledButton onClick={() => toggleScreenShare()}>
      Share Screen
      <Toggle
        checked={isSharingScreen}
        icons={{
          checked: <FontAwesomeIcon size="sm" icon={faDesktop} />,
          unchecked: null,
        }}
        onChange={() => {}}
      />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  margin: 16px;
  &.btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .react-toggle {
      margin-left: 10px;
    }
  }
`;
