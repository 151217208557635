import { useCollectionData } from "react-firebase-hooks/firestore";
import { useFirebase } from "hooks/useFirebase";
import { useFirebaseRef as useUserCalculatorFirebaseRef } from "../useUserCalculator/useUserCalculator";

export const useHistory = (userId) => {
  const firebase = useFirebase();
  const ref = useUserCalculatorFirebaseRef(userId)?.collection("history");
  const [history, loading, error] = useCollectionData(
    ref?.orderBy("createdAt", "desc")?.limit(12),
    { idField: "id" }
  );

  const add = (item) => {
    return ref.add({
      ...item,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }).catch((e) => console.dir(e));
  };

  const clear = () => {
    ref.get().then((querySnapshot) => {
      querySnapshot.docs.forEach((snapshot) => {
        snapshot.ref.delete();
      });
    });
  };

  return { history, loading, error, add, clear };
};
