import { useState } from "react";
import { useOkoApi } from "hooks/useOkoApi/useOkoApi";
import { useSessionContext } from "hooks/useSession";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { useActivity } from "hooks/useActivity/useActivity";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { useMembers } from "hooks/livekit/useMembers/useMembers";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useUserCalculatorUpdate } from "hooks/activities/calculator/useUserCalculator/useUserCalculator";
import { constants } from "utils";

export const useCalculatorOkoSession = () => {
  const { fetch } = useOkoApi();
  const { session } = useSessionContext();
  const { change: changeActivity } = useSessionActivity();
  const { update: updateActivity, activity } = useActivity();
  const { members } = useMembers();
  const { authUserData } = useAuthUserDataContext();
  const [challengeContext, setChallengeContext] = useState({});
  const [isAllowingGesture, setAllowingGesture] = useState(false);

  const { update: updateUserCalculator } = useUserCalculatorUpdate();

  useDataTrack((e) => {
    if (session?.sessionActivityType !== constants.CRAZY_CALCULATOR_ENUM) return;

    if (e?.event_type !== "state_change") return;

    if (e?.data?.state === "introduce") {
      setAllowingGesture(true);
      changeActivity({ id: "meet" });
    }

    if (e?.data?.state === "challenge") {
      setAllowingGesture(true);
      changeActivity({ id: "calculator" });
      const challengeContext = { ...e?.data?.context, event_id: e.event_id };
      setChallengeContext(challengeContext);
      const bannedButtonIds = e?.data?.context.broken_keys.map((b) =>
        isNaN(b) ? b : parseInt(b)
      );
      const goal = Number(e?.data?.context.target_answer);
      const instructions = `Try to make the number ${goal}.`;
      updateActivity(
        {
          bannedButtonIds,
          goal,
          instructions,
          presentingUserId: false,
          challengeContext,
        },
        "calculator"
      );

      // Clear all user calculators at start of challenge
      members.forEach(({ userId }) => {
        updateUserCalculator(userId, { formula: "" }, true);
      });
    }

    if (e?.data?.state === "evaluate") {
      const participantId = e?.data?.previous_context?.oko_user_id;
      const presentingUserId = members.find(
        (m) => m.participant.userId === participantId
      )?.userId;
      setAllowingGesture(false);

      if (presentingUserId) {
        updateActivity({ presentingUserId }, "calculator");
      }
    }

    if (e?.data?.state === "teacher_solution") {
      setAllowingGesture(false);

      const teacherSid = e?.data?.context?.teacher_sid;
      const teacherUniqueId = e?.data?.context?.teacher_unique_id;
      const presentingUserId = members.find(
        (m) => m.participant.sid === teacherSid
      )?.userId;

      if (
        presentingUserId &&
        teacherUniqueId === presentingUserId &&
        presentingUserId === authUserData.id
      ) {
        updateActivity({ presentingUserId, startFormula: "" }, "calculator");
      }
    }

    if (e?.data?.state === "complete") {
      setAllowingGesture(true);
      changeActivity({ id: "waiting-room" });
      updateActivity(
        {
          title: "Thanks for playing OKO.",
          subtitle: "See you again soon!",
          showExitButton: true,
          hideLoadingIndicator: true,
        },
        "waiting-room"
      );
    }
  });

  const calculatorResponse = async ({
    participantId,
    formula,
    answer,
    partial,
  }) => {
    const currChallengeContext = activity.challengeContext || challengeContext;

    const payload = {
      challenge_id: currChallengeContext.challenge_id,
      linked_event_id: currChallengeContext.event_id,
      oko_user_id: participantId,
      answer: (answer + "").replace("0.", "1.").replace(".", ""),
      expression: formula,
      partial: !!partial,
    };

    return fetch(`session/${session.okoSessionId}/challenge/response`, {
      payload,
    });
  };

  return { isAllowingGesture, calculatorResponse };
};
