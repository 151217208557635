import { useContext } from "react";
import { LiveKitProgrammableVideoContext } from "../useLiveKitProgramableVideo/useLiveKitProgramableVideo";

export default function useVideoContext() {
  const context = useContext(LiveKitProgrammableVideoContext);

  if (!context) {
    throw new Error(
      "useVideoContext must be used within a LiveKitProgrammableVideoProvider"
    );
  }
  return context;
}
