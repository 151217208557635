import styled from "styled-components";
import { useEffect, useState } from "react";
import { ParticipantEvent, Track } from "livekit-client";
import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay } from "./Overlay/Overlay";
import ParticipantTracks from "../ParticipantTracks/ParticipantTracks";
import { ObserverParticipant } from "../../Observe/ObserverParticipant";

export const Participant = ({
  participant,
  videoOnly,
  enableScreenShare,
  isLocalParticipant,
  isSpectator,
}) => {

  const [isAudioMuted, setIsAudioMuted] = useState(
    Array.from(participant?.trackPublications?.values())?.find((trackPublication) =>
      trackPublication.source.includes(Track.Source.Microphone))?.track?.isMuted
  );
  const [videoTrack, setVideoTrack] = useState(
    Array.from(participant?.trackPublications?.values())?.find((trackPublication) =>
      trackPublication.source.includes(Track.Source.Camera))?.track
  );
  const [isVideoMuted, setIsVideoMuted] = useState(videoTrack?.isMuted);

  useEffect(() => {
    if (!participant) return;

    const audioTrack = Array.from(participant?.trackPublications?.values())?.find((trackPublication) =>
        trackPublication.source.includes(Track.Source.Microphone))?.track;
    const videoTrack = Array.from(participant?.trackPublications?.values())?.find((trackPublication) =>
        trackPublication.source.includes(Track.Source.Camera))?.track;

    if (audioTrack) {
      setIsAudioMuted(audioTrack.isMuted);
    }
    if (videoTrack) {
      setVideoTrack(videoTrack);
      setIsVideoMuted(videoTrack.isMuted);
    }

    const trackMuted = (track) => {
      if (track.source === Track.Source.Microphone) {
        setIsAudioMuted(true);
      } else if (track.source === Track.Source.Camera) {
        setIsVideoMuted(true);
      }
    };

    const trackUnmuted = (track) => {
      if (track.source === Track.Source.Microphone) {
        setIsAudioMuted(false);
      } else if (track.source === Track.Source.Camera) {
        setIsVideoMuted(false);
      }
    };

    participant.on(ParticipantEvent.TrackMuted, trackMuted);
    participant.on(ParticipantEvent.TrackUnmuted, trackUnmuted);

    return () => {
      participant.off(ParticipantEvent.TrackMuted, trackMuted);
      participant.off(ParticipantEvent.TrackUnmuted, trackUnmuted);
    };
  }, [participant, participant?.trackPublications?.values()]);

  let style = {};
  if (isSpectator) style = { overflow: "visible"};

  return (
    <Container style={style}>
      <Content>
        {isSpectator ? (
          <ObserverParticipant
            participant={participant}
            isAudioMuted={isAudioMuted}
            isVideoMuted={!videoTrack || isVideoMuted}
          />
        ) : (
          <>
            <AwayIcon icon={faUserAstronaut} />
            <ParticipantTracks
              participant={participant}
              videoOnly={videoOnly}
              enableScreenShare={enableScreenShare}
              isLocalParticipant={isLocalParticipant}
              isVideoMuted={!videoTrack || isVideoMuted}
            />
            <Overlay participant={participant} isAudioMuted={isAudioMuted} isVideoMuted={!videoTrack || isVideoMuted} />
          </>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-bottom: ${(9 / 16) * 100}%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: linear-gradient(to top, #38425a, #293042);

  & video {
    filter: none;
    object-fit: contain !important;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const AwayIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 50%;
  bottom: 50%;
  color: white;
  width: 50% !important;
  height: 50%;
  transform: translate(-50%, 50%);
`;
