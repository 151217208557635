import { RoomConnectOptions } from "livekit-client";

/**
 * LiveKit Room connection options: {@link RoomConnectOptions}
 * or {@link https://docs.livekit.io/client-sdk-js/interfaces/RoomConnectOptions.html}
 * There is no need to customize any of those at this point as LiveKit has pretty well-tuned default values:
 * {@link RoomConnectOptions.autoSubscribe},
 * {@link RoomConnectOptions.maxRetries},
 * {@link RoomConnectOptions.peerConnectionTimeout},
 * {@link RoomConnectOptions.rtcConfig},
 * {@link RoomConnectOptions.websocketTimeout}
 * @return roomConnectOptionDefaults
 */
export default function useConnectionOptions() {
  return null;
};
