import styled from "styled-components";
import { useState } from "react";
import { useFirebase } from "hooks/useFirebase";
import { VideoActionBar } from "../shared/VideoActionBar/VideoActionBar";
import { NotFound } from "./activities/NotFound/NotFound";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { OkoAssistant } from "components/pages/Session/OkoLesson/OkoAssistant/OkoAssistant";
import { Button } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@bootstrap-styled/v4";

export const Activity = ({ disable }) => {
  const { activity } = useSessionActivity();
  const Activity = activity?.component;

  return (
    <Container
      className="fullscreen"
      style={{
        overflowY: "hidden",
        pointerEvents: disable ? "none" : "auto",
      }}
    >
      <div style={{ display: "contents" }}>
        <div style={{ height: "80px", position: "fixed" }}>
          <OkoAssistant />
        </div>
        <div style={{ bottom: 0, position: "fixed", top: "80px" }}>
          {Activity ? <Activity /> : <NotFound />}
        </div>
      </div>
      <Leave></Leave>
      <VideoActionBarWrapper>
        <VideoActionBar />
      </VideoActionBarWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: #edf2f9;
  display: flex;
  position: relative;
`;

const VideoActionBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 184px / 2);
  padding: 8px;
`;

const LeaveButton = styled(Button)`
  right: 10px;
  top: 10px;
  position: absolute;
  border: none;
  border-color: transparent !important;
  color: #444 !important;
  background: transparent !important;
  opacity: 0.4;
  box-shadow: none !important;
`;

const Leave = () => {
  const firebase = useFirebase();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onLeave = async () => {
    await firebase.auth().signOut();
    window.location.href = "https://okolabs.ai/";
  };

  return (
    <>
      <LeaveButton
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </LeaveButton>
      <Modal isOpen={isModalOpen} toggle={handleClose}>
        <ModalHeader>Exit Session?</ModalHeader>
        <ModalBody>
          Are you sure you want to quit and leave the session?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onLeave();
            }}
          >
            Exit
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
