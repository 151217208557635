import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@bootstrap-styled/v4";
import { useState } from "react";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import { PreviewMultipleChoiceQuestionModal } from "./PreviewMultipleChoiceQuestionModal";

export const EditMultipleChoiceQuestionModal = ({ challenge, isModalOpen, modalTitle, setIsModalOpen }) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal style={{ maxWidth: "1000px" }} size="lg" isOpen={isModalOpen} toggle={handleClose}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <MultipleChoiceQuestion challenge={challenge} showHeader={false}/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <PreviewMultipleChoiceQuestionModal challenge={challenge}
                                          isModalOpen={isPreviewModalOpen}
                                          setIsModalOpen={setIsPreviewModalOpen}/>
    </div>
  );
};
