import { Button, H3, Modal, ModalBody, ModalFooter, ModalHeader } from "@bootstrap-styled/v4";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { buttonIdsToStr, handleSaveChallengeError } from "utils/challenge";
import { CalculatorRender } from "../Calculator/CalculatorRender";
import { useButtonIds } from "../../../../../../../hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { request } from "../../../../../../../hooks/useOkoApi/useOkoApi";
import { constants } from "utils";

export const PreviewCrazyCalculatorQuestionModal = ({ challenge, isModalOpen, setIsModalOpen }) => {
  const [error, setError] = useState();
  const buttonIds = useButtonIds();

  const handleClose = () => {
    setIsModalOpen(false);
    setError(null);
  };

  const handleSave = async () => {
    try {
      await request('questions/update', {
        payload: challenge
      });
      setIsModalOpen(false);
    } catch (e) {
      Sentry.captureException(e);
      setError(e?.detail || e?.message || "An unknown error occurred.");
    }
  };

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={handleClose}>
      <ModalHeader>Preview Challenge</ModalHeader>
      <ModalBody style={{ alignSelf: "center" }}>
        <H3>Try to make the number {challenge.target_answer}.</H3>
        <CalculatorRender
          buttonIds={buttonIdsToStr(buttonIds)}
          bannedButtonIds={challenge.broken_keys}
          formula={""}
          clear={() => {}}
          onButtonClick={() => {}}
          buttonAnimTrigger={{}}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </ModalFooter>
      {error && <Alert style={{ overflowWrap: "break-word" }}
                       dismissible
                       onClose={() => setError(null)}
                       variant="danger">
        {JSON.stringify(handleSaveChallengeError(constants.CRAZY_CALCULATOR_ENUM, error))}
      </Alert>}
    </Modal>
  );
};
