import "./answerOption.css"
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AnswerOption = ({ index, answerOptions, setAnswerOptions, solutionIndex, setSolutionIndex }) => {

  const handleTextChange = (e) => {
    const answerOptionsCopy = JSON.parse(JSON.stringify(answerOptions));
    answerOptionsCopy[index] = e.target.value;
    setAnswerOptions(answerOptionsCopy);
  };

  const handleSolutionIndexChange = (e) => {
    setSolutionIndex(parseInt(e.target.value));
  };

  const deleteAnswerOption = (e) => {
    const answerOptionsCopy = JSON.parse(JSON.stringify(answerOptions));
    answerOptionsCopy.splice(index, 1);
    setAnswerOptions(answerOptionsCopy);
    // Maintain correct solution index if the deleted answer option precedes the one with solution
    if (solutionIndex > 0 && solutionIndex >= index) {
      setSolutionIndex(solutionIndex - 1);
    }
  };

  let answerOptionClass = "answer-input margin-right-5";
  if (index === solutionIndex) {
    answerOptionClass = answerOptionClass.concat(" valid-answer");
  }

  return (
    <div>
      <span>
        <input className="margin-right-5"
               type="radio"
               value={index}
               checked={index === solutionIndex}
               onChange={handleSolutionIndexChange}/>
        <input className={answerOptionClass}
               type="text"
               value={answerOptions[index]}
               onChange={handleTextChange}/>
        <button style={{ borderRadius: "100px", borderWidth: "1px" }}
                value={index}
                onClick={deleteAnswerOption}
                disabled={answerOptions.length === 1}>
          <FontAwesomeIcon size="sm" icon={faTrash} />
        </button>
      </span>
    </div>
  );
};
