import { useEffect, useState } from "react";

import { useTrigger } from "hooks/useTrigger";
import { useButtonIds } from "hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { useCalculator } from "hooks/activities/calculator/useCalculator/useCalculator";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { usePresentingUser } from "hooks/activities/calculator/usePresentingUser/usePresentingUser";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { CalculatorRender } from "./CalculatorRender";

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const Calculator = () => {
  const [solutionKeyPress, setSolutionKeyPress] = useState('');
  const buttonIds = useButtonIds();
  const { bannedButtonIds, push, formula, submit, clear } = useCalculator();
  const { presentingUserId } = usePresentingUser();
  const { authUserData } = useAuthUserDataContext();

  const {
    trigger: buttonAnimTrigger,
    dispatch: dispatchButtonAnimTrigger,
  } = useTrigger();

  const onButtonClick = (buttonId) => {
    if (buttonId === "=") {
      dispatchButtonAnimTrigger(submit() ? "shakeRight" : "shakeWrong", "=");
    } else {
      push(buttonId);
    }
  };

  useDataTrack(async (e) => {
    // Backend (Oko) commandeering calculator to provide sample solution key sequence
    if (e?.data?.event_type === "key_sequence") {
      const { duration, sequence, participant_unique_id: participantId } = e.data;
      if (duration <= 0 || !Array.isArray(sequence) || !sequence.length) {
        console.log("Calculator:onDataTrack invalid payload received: ", e.data);
        return;
      }
      const durationPer = 1000 * duration / sequence.length;
      // participant for which calculator is being commandeered
      const isDrivingParticipant = (participantId === presentingUserId && presentingUserId === authUserData.id);
      for (let key of sequence) {
        if (isDrivingParticipant) {
          // only the driver presses the key
          setSolutionKeyPress(key);
        }
        // equals key submits. no need for additional animation or sleep
        if (key !== "=") {
          // animate the button for driving participant as well as passive observer
          dispatchButtonAnimTrigger("whileTapRemote", (isNaN(key) ? key : parseInt(key)));
          // sleep for next key press to mimic manual human key presses
          await sleep(durationPer * (1 + 0.1 * Math.random()));
        }
      }
    }
  });

  useEffect(() => {
    if (solutionKeyPress === "=") {
      dispatchButtonAnimTrigger(submit() ? "shakeRight" : "shakeWrong", "=");
    } else {
      push(solutionKeyPress);
    }

  }, [solutionKeyPress]);

  return (
    <CalculatorRender buttonIds={buttonIds}
                      bannedButtonIds={bannedButtonIds}
                      formula={formula}
                      clear={clear}
                      onButtonClick={onButtonClick}
                      buttonAnimTrigger={buttonAnimTrigger}/>
  );
};
