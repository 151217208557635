export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const colors = {
  artificialIris: "#605DEC",
  backGroundSecondary: "#F2F4F6",
  blue: "#292865",
  gray100: "#F5F5F5",
  gray200: "#CDCDCD",
  lightblue: "#D3D3F8",
  milkGlass200: "#FDFCFA",
  milkGlass500: "#FAF7F2",
  milkGlass600: "#EEECE7",
  milkGlass800: "#B9B7B3",
  milkGlass950: "#6B6967",
  neutral50: "#FCFCFD",
  neutral100: "#F2F4F7",
  neutral200: "#7A7F89",
  neutral300: "#ABB1BA",
  nightlyWoods500: "#002E19",
  nightlyWoods600: "#002C18",
  nightlyWoods700: "#002715",
  nightlyWoods900: "#001C0F",
  nightlyWoods950: "#00140B",
};

export const constants = {
  ACTIVITY: "Activity",
  GRADE_LEVEL: "Grade Level",
  DOMAIN: "Domain",
  CLUSTER: "Cluster",
  STANDARD: "Standard",
  ACTIVITY_PLACEHOLDER: "Select Activity",
  GRADE_LEVEL_PLACEHOLDER: "All Grades",
  DOMAIN_PLACEHOLDER: "All Domains",
  CLUSTER_PLACEHOLDER: "All Clusters",
  STANDARD_PLACEHOLDER: "All Standards",
  CRAZY_CALCULATOR: "Crazy Calculator",
  CRAZY_CALCULATOR_ENUM: "crazy_calculator",
  LETS_TALK: "Let's Talk",
  CONSENSUS_ENUM: "consensus",
  GROUP_CARD_VIEW_MESSAGE: "You can assign a standard to individual group codes by selecting from the drop-downs below",
  CRAZY_CALC_GROUP_CODE_CARD_MESSAGE: "*For Crazy Calculator sessions, OKO dynamically adjusts the difficulty of questions based on student responses.",
  DASHBOARD: "Dashboard",
  SESSION_HISTORY: "Session History",
  EDUCATOR_PATH: "/educator",
  EDUCATOR_ROUTE_NAME: "EducatorProfile",
  EDUCATOR_LOGIN_PATH: "/educator/login",
  EDUCATOR_LOGIN_ROUTE_NAME: "EducatorLogin",
  SESSION_HISTORY_PATH: "/session_history",
  SESSION_HISTORY_ROUTE_NAME: "SessionHistory",
  STUDENT_LOGIN_CARD_TITLE: "Student Login",
  STUDENT_LOGIN_CARD_MESSAGE: "This is where your students can enter in your Group Codes",
  SETTINGS: "Settings",
  EDUCATOR_SETTINGS_PATH: "/educator/settings",
  EDUCATOR_SETTINGS_ROUTE_NAME: "EducatorSettings",
  STUDENT: "student",
  ANONYMOUS: "anonymous",
  GOOGLE: "google",
};

export const getActivityName = (activity) => {
  let activityName;
  switch (activity) {
    case constants.CRAZY_CALCULATOR_ENUM:
      activityName = constants.CRAZY_CALCULATOR;
      break;
    case constants.CONSENSUS_ENUM:
      activityName = constants.LETS_TALK;
      break;
    default:
      activityName = "N/A";
  }
  return activityName;
};
