import styled from "styled-components";
import { ToggleLocalCamButton, ToggleLocalMicButton } from "./ActionButton";
import { ToggleSettingsModalButton } from "./ToggleSettingsModalButton";

export const VideoActionBar = ({ fullHeight }) => {
  return (
    <Container fullHeight={fullHeight}>
      <ToggleLocalCamButton />
      <ToggleLocalMicButton style={{ marginBottom: 4, marginTop: 4 }} />
      <ToggleSettingsModalButton />
    </Container>
  );
};

const gutter = 8;

const Container = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 0 1px #ebe9f1;
  ${(props) => (props.fullHeight ? "height: 100%;" : "")}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 100pc;

  padding: ${gutter}px;
`;
