import { useState } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { Sidebar } from "./Sidebar/Sidebar";
import { HomePanel } from "./HomePanel/HomePanel";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import { VideoBarWidget } from "./VideoBarWidget";

export const Dashboard = () => {
  const [panel, setPanel] = useState({
    component: HomePanel,
    label: "Dashboard",
  });

  const PanelComponent = panel?.component;

  const changeToPanel = ({ label, component }) => {
    setPanel({ component, label });
  };

  return (
    <Screen>
      <Sidebar changeToPanel={changeToPanel} />
      <PanelContainer>
        <div className="p-3" style={{ flex: 1 }}>
          {PanelComponent && <PanelComponent changeToPanel={changeToPanel} />}
        </div>
        <VideosBar includeObservers={true} videoWidget={VideoBarWidget} />
      </PanelContainer>
    </Screen>
  );
};

const Screen = styled(Fullscreen)`
  background: #edf2f9;
  display: flex;
  width: 100%;
  position: absolute;
  align-items: stretch;
`;

const PanelContainer = styled(Fullscreen)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
