import Publication from "../Publication/Publication";
import usePublications from "hooks/livekit/usePublications/usePublications";
import { Track } from "livekit-client";
/*
 *  The object model for the Room object (found here: https://docs.livekit.io/client-sdk-js/classes/Room.html) shows
 *  that remoteParticipants objects have trackPublications, and trackPublications have tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */
export default function ParticipantTracks({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
  isVideoMuted,
}) {
  const publications = usePublications(participant);
  let filteredPublications;
  if (
    enableScreenShare &&
    publications.some((p) => p.trackName.includes("screen"))
  ) {
    filteredPublications = publications.filter(
      (p) => !p?.source.includes(Track.Source.Camera)
    );
  } else {
    filteredPublications = publications.filter(
      (p) => !p.trackName.includes("screen")
    );
  }
  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
          isVideoMuted={isVideoMuted}
        />
      ))}
    </>
  );
}
