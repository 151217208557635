import { createContext, useContext } from "react";
import { useFirebaseRef as useSessionFirebaseRef } from "hooks/useSession";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useSessionContext } from "hooks/useSession";

export const useFirebaseRef = () => {
  const { session } = useSessionContext();
  const sessionId = session?.id || "SESSIONNOTFOUND";
  const ref = useSessionFirebaseRef(sessionId);

  return ref?.collection(`activities`)?.doc("calculator");
};

export const useCalculatorActivity = () => {
  const ref = useFirebaseRef();

  let [calcAct, loading, error] = useDocumentData(ref);

  const instructions = calcAct?.instructions || `Try to make the number 5!`;
  const bannedButtonIds = calcAct?.bannedButtonIds || [7, 5, 4, 3, 2, "*", "/"];
  const goal = calcAct?.goal || 5;
  const startFormula = calcAct?.startFormula || "";
  const presentingUserId = calcAct?.presentingUserId || false;

  const update = (data) => {
    return ref.set(data, { merge: true });
  };

  const togglePresentingUser = (userId) => {
    if (calcAct?.presentingUserId === userId)
      return update({ presentingUserId: null });

    update({ presentingUserId: userId });
  };

  const setPresentingUser = (userId) => {
    update({ presentingUserId: userId });
  };

  return {
    bannedButtonIds,
    goal,
    instructions,
    startFormula,
    loading,
    error,
    update,
    togglePresentingUser,
    setPresentingUser,
    presentingUserId,
  };
};

const Context = createContext();

export const CalculatorActivityProvider = ({ children }) => {
  const value = useCalculatorActivity();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCalculatorActivityContext = () => {
  return useContext(Context);
};
