import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideoSlash,
  faMicrophoneSlash,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useObserver } from "hooks/useObserver/useObserver";

export const Overlay = ({ participant, isAudioMuted, isVideoMuted, isSpectator, isScreenShare }) => {
  const { isObserver } = useObserver(participant.userId);

  return (
    <>
      {isObserver && (
        <ObserverContainer>
          <FontAwesomeIcon icon={faEyeSlash} />
        </ObserverContainer>
      )}
      {isSpectator ? (
        <ObserverUserInfo>
          <span>{ !isScreenShare ? participant?.name : participant?.name + "'s screen"}</span>
          {isVideoMuted && <FontAwesomeIcon className="ml-1" icon={faVideoSlash} />}
          {isAudioMuted && (
            <FontAwesomeIcon className="ml-1" icon={faMicrophoneSlash} />
          )}
        </ObserverUserInfo>
        ) : (
          <UserInfo>
            <span>{participant?.name}</span>
            {isVideoMuted && <FontAwesomeIcon className="ml-1" icon={faVideoSlash} />}
            {isAudioMuted && (
              <FontAwesomeIcon className="ml-1" icon={faMicrophoneSlash} />
            )}
          </UserInfo>
        )}
    </>
  );
};

const UserInfo = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: #293042e0;
  color: white;
  padding: 0px 12px;
  margin: 8px;
  border-radius: 100pc;
  height: 24px;
`;

const ObserverUserInfo = styled.div`
  position: relative;
  left: 0;
  bottom: 48px;
  background: #293042e0;
  color: white;
  padding: 0px 12px;
  margin: 8px;
  border-radius: 100pc;
  height: 24px;
  width: fit-content;
`;

const ObserverContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: #293042e0;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
`;
