import "./login.css";
import { LoginCard } from "components/pages/Home/LoginCard";
import logo from "assets/OKO_Core_Logotype2.svg";
import { GoogleSignInButton } from "components/shared/SignInButton/GoogleSignInButton";
import { useHistory } from "react-router-dom";
import { generateRoutePath } from "utils/generateRoutePath";
import { useState } from "react";
import { SettingsModal } from "../Educator/SignUp/SettingsModal";

export const Login = ({ redirectPath, redirectParams, isSignUp }) => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const history = useHistory();

  const onSuccess = async () => {
    if (redirectPath) history.push(generateRoutePath(redirectPath, redirectParams));
    if (isSignUp) setIsSettingsModalOpen(true);
  };

  return (
    <>
      {isSettingsModalOpen && <SettingsModal isOpen isOnboarding setIsOpen={setIsSettingsModalOpen} />}
      <div style={{ display: "flex", alignItems: "center", maxHeight: "100vh", maxWidth: "100vw" }}>
        <div className="login-decoration login-decoration-portrait">
        </div>
        <LoginCard>
          <div style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            padding: "32px",
            textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ width: "196px" }} />
            {isSignUp && <p style={{ fontSize: "24px" }}>Educator Sign Up</p>}
            {isSignUp && <p style={{ fontSize: "18px" }}>
              We're excited for your students to start using OKO!
            </p>}
            <GoogleSignInButton onSuccess={onSuccess} />
          </div>
        </LoginCard>
      </div>
    </>
  );
};
