import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import useMediaStreamTrack from "hooks/livekit/useMediaStreamTrack/useMediaStreamTrack";
import useVideoTrackDimensions from "hooks/livekit/useVideoTrackDimensions/useVideoTrackDimensions";

const Video = styled("video")({
  width: "100%",
  height: "100%",
  position: "relative",
});

export const VideoTrack = ({ track, isLocal, priority, disabled }) => {
  const ref = useRef(null);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    if (!track) return;
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== "environment";

  const style = {
    opacity: disabled ? 0 : 1,
    transform: isLocal && isFrontFacing ? "rotateY(180deg)" : "",
    objectFit:
      isPortrait || track?.trackName?.includes("screen") ? "contain" : "cover",
  };
  return <Video ref={ref} style={style} />;
};
