import { useEffect, useState } from "react";
import { Presets } from "./Presets";
import { Calculator } from "./Calculator";
import { Users } from "./Users";
import { useCalculatorActivity } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";
import { useMembers } from "hooks/livekit/useMembers/useMembers";
import { useUserCalculatorUpdate } from "hooks/activities/calculator/useUserCalculator/useUserCalculator";
import { useOkoLesson } from "hooks/useOkoLesson/useOkoLesson";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";

import { Row, Col, Card, CardBlock, H6 } from "@bootstrap-styled/v4";

export const Settings = ({ onSave = () => {}, onClose = () => {} }) => {
  const [presets, setPresets] = useLocalStorage(
    "CalculatorDefaultPresets",
    defaultPresets
  );

  const {
    loading,
    goal,
    instructions,
    bannedButtonIds,
    startFormula,
    update,
    setPresentingUser,
  } = useCalculatorActivity();
  const { authUserData } = useAuthUserDataContext();

  const { members } = useMembers({ includeObservers: true });
  const { update: updateUserCalculator } = useUserCalculatorUpdate();

  const { say } = useOkoLesson();

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (formData !== null || loading) return;

    setFormData({
      goal,
      instructions,
      bannedButtonIds,
      startFormula,
    });
  }, [loading, goal, instructions, bannedButtonIds, formData, startFormula]);

  const onSelectPreset = (preset) => {
    setFormData(preset);
  };

  const onPublish = () => {
    update(formData);

    members.forEach(({ userId }) => {
      updateUserCalculator(
        userId,
        { formula: formData.startFormula || "" },
        formData.clearHistory
      );
    });

    say({ useLocal: true, phrase: formData.instructions });
    setPresentingUser(authUserData.id);

    onSave();
    onClose();
  };

  const addToPresets = () => {
    setPresets([...presets, formData]);
  };

  return (
    <Row>
      <Col md={8}>
        <H6>Calculator</H6>
        <Card>
          <CardBlock>
            <div className="d-flex">
              <div style={{ width: 280 }}>
                {formData && !loading && (
                  <Calculator
                    onPublish={onPublish}
                    formData={formData}
                    setFormData={setFormData}
                    addToPresets={addToPresets}
                  />
                )}
              </div>
              <div className="p-2" style={{ flex: 1 }}>
                <Presets
                  onSelect={onSelectPreset}
                  presets={presets}
                  setPresets={setPresets}
                  addToPresets={addToPresets}
                />
              </div>
            </div>
          </CardBlock>
        </Card>
      </Col>
      <Col md={4}>
        <H6>Users</H6>
        <Users />
      </Col>
    </Row>
  );
};

const defaultPresets = [
  {
    bannedButtonIds: [2, 4, 5],
    startFormula: "",
    clearHistory: true,
    instructions: "Try to make the number 5!",
    goal: 5,
  },
  {
    bannedButtonIds: [2, 4, 5, 6],
    startFormula: "",
    clearHistory: true,
    instructions: "Try to make the number 8!",
    goal: 8,
  },
  {
    bannedButtonIds: [3, 4, 5, 6, 7, 8],
    startFormula: "",
    clearHistory: true,
    instructions: "Try to make the number 3!",
    goal: 3,
  },
];
