import "./fireworks.scss";
export const Fireworks = () => {
  return (
    <>
      <div className="firework"></div>
      <div className="firework"></div>
      <div className="firework"></div>
      <div className="firework"></div>
    </>
  );
};
