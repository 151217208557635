import "./brokenKey.css"
import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButtonIds } from "../../../../../../../hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { buttonIdsToStr } from "utils/challenge";

export const BrokenKey = ({ index, brokenKeys, setBrokenKeys }) => {
  const buttonIds = useButtonIds();
  const handleBrokenKeyChange = (e) => {
    if (e.target.value === "" ||
      (buttonIdsToStr(buttonIds).includes(e.target.value) && !brokenKeys.includes(e.target.value))) {
      const brokenKeysCopy = JSON.parse(JSON.stringify(brokenKeys));
      brokenKeysCopy[index] = e.target.value;
      setBrokenKeys(brokenKeysCopy);
    }
  };

  const deleteBrokenKey = (e) => {
    const brokenKeysCopy = JSON.parse(JSON.stringify(brokenKeys));
    brokenKeysCopy.splice(index, 1);
    setBrokenKeys(brokenKeysCopy);
  };

  return (
    <div>
      <span>
        <input className="brokenKey margin-right-5"
               type="text"
               minLength="1"
               maxLength="1"
               value={brokenKeys[index]}
               onChange={handleBrokenKeyChange}/>
        <button style={{ borderRadius: "100px", borderWidth: "1px" }}
                value={index}
                onClick={deleteBrokenKey}>
          <FontAwesomeIcon size="sm" icon={faTrash} />
        </button>
      </span>
    </div>
  );
};
