import { Button, ButtonGroup } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCat, faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export const ActivityButton = ({
  activity,
  onClick = () => {},
  onPlayClick,
  isActive,
}) => {
  return (
    <ButtonGroup size="lg" className="mb-2 w-100">
      <Button
        onClick={() => {
          onClick(activity);
        }}
        block
        className="text-left"
        style={{ background: !isActive ? "#293042" : "" }}
      >
        <FontAwesomeIcon icon={activity.icon || faCat} className="mr-3" />
        {activity.label}
      </Button>

      {onPlayClick && (
        <Button
          style={{
            background: "#025DAD",
            borderColor: "#025DAD",
            width: 64,
            padding: 0,
          }}
          onClick={() => onPlayClick(activity)}
        >
          <FontAwesomeIcon icon={faPlayCircle} size="lg" />
        </Button>
      )}
    </ButtonGroup>
  );
};
