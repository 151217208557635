import { useEffect } from 'react';
import { RoomEvent } from "livekit-client";
export default function useHandleRoomDisconnectionErrors(room, onError) {
    useEffect(() => {
        const onDisconnected = (room, error) => {
            if (error) {
                onError(error);
            }
        };
        room.on(RoomEvent.Disconnected, onDisconnected);
        return () => {
            room.off(RoomEvent.Disconnected, onDisconnected);
        };
    }, [room, onError]);
}
