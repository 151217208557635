import "./brokenKeys.css"
import React from "react";
import { H6 } from "@bootstrap-styled/v4";
import { BrokenKey } from "./BrokenKey";
import { useButtonIds } from "../../../../../../../hooks/activities/calculator/useButtonMaps/useButtonMaps";

export const BrokenKeys = ({ brokenKeys, setBrokenKeys }) => {

  return (
    <div>
      <H6>Broken Keys - only the following non-duplicated values are allowed: {useButtonIds().toString()}</H6>
      <div className="brokenKeys">
      {brokenKeys && brokenKeys.map((standard, index) => (
        <BrokenKey index={index}
                   brokenKeys={brokenKeys}
                   setBrokenKeys={setBrokenKeys}
        />
      ))}
      </div>
    </div>
  );
};
