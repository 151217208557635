import "./crazyCalculatorQuestion.css"
import React, { useState } from "react";
import { H6 } from "@bootstrap-styled/v4/";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import { PreviewCrazyCalculatorQuestionModal } from "./PreviewCrazyCalculatorQuestionModal";
import { BrokenKeys } from "./BrokenKeys";
import { buttonIdsToStr } from "../../../../../../../utils/challenge";
import { useButtonIds } from "../../../../../../../hooks/activities/calculator/useButtonMaps/useButtonMaps";
import { isEveryAllowedCharInString } from "utils";

export const CrazyCalculatorQuestion = ({ challenge, index, showHeader }) => {
  const [brokenKeys, setBrokenKeys] = useState(challenge.broken_keys ?? []);
  const [difficulty, setDifficulty] = useState(challenge.difficulty);
  const [sampleSolution, setSampleSolution] = useState(challenge.sample_solution);
  const [targetAnswer, setTargetAnswer] = useState(challenge.target_answer);
  const [initialExpression, setInitialExpression] = useState(challenge.initial_expression ?
    challenge.initial_expression.join("") : "");
  const [comments, setComments] = useState(challenge.comments);
  const [status, setStatus] = useState(challenge.status ?? "active");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const buttonIds = useButtonIds();

  const buildChallengeRequest = () => {
    const request = {};
    request.id = challenge.id;
    request.difficulty = difficulty;
    request.broken_keys = brokenKeys;
    request.sample_solution = sampleSolution;
    request.target_answer = targetAnswer;
    request.initial_expression = initialExpression ? initialExpression.split(""): null;
    request.comments = comments;
    request.status = status;
    return request;
  };

  const handlePreview = () => {
    setIsPreviewModalOpen(true);
  };

  const handleTargetAnswer = (e) => {
    setTargetAnswer(e.target.value);
  };

  const handleSampleSolution = (e) => {
    setSampleSolution(e.target.value);
  };

  const handleDifficulty = (e) => {
    setDifficulty(e.target.value);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleInitialExpression = (e) => {
    if (e.target.value === "" || isEveryAllowedCharInString(e.target.value, buttonIdsToStr(buttonIds))) {
      setInitialExpression(e.target.value);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const addBrokenKey = () => {
    const newBrokenKeys = JSON.parse(JSON.stringify(brokenKeys));
    newBrokenKeys.push("");
    setBrokenKeys(newBrokenKeys);
  };

  return (
    <div>
      <Accordion.Item eventKey={index} key={index}>
        {showHeader && <Accordion.Header>
          ID: {challenge.id}&nbsp;&nbsp;&nbsp;
          Difficulty: {difficulty}&nbsp;&nbsp;&nbsp;
          Target Answer: {targetAnswer}&nbsp;&nbsp;&nbsp;
          Broken Keys: {brokenKeys.toString()}&nbsp;&nbsp;&nbsp;
          Updated At: {challenge.updated_at}&nbsp;&nbsp;&nbsp;
          Updated By: {challenge.updated_by}&nbsp;&nbsp;&nbsp;
          Status: {status.toUpperCase()}&nbsp;&nbsp;&nbsp;
        </Accordion.Header>}
        <Accordion.Body>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
              <div className="challenge-prop horizontal">
                <H6 className="header-margin-right">Target Answer</H6>
                <input className="target-answer-width round-box"
                       type="text"
                       value={targetAnswer}
                       onChange={handleTargetAnswer}/>
              </div>
              <div className="challenge-prop horizontal">
                <H6 className="header-margin-right">Difficulty</H6>
                <input className="input-number round-box"
                       type="number"
                       min="1"
                       max="5"
                       value={difficulty}
                       onChange={handleDifficulty}/>
              </div>
              <div className="challenge-prop horizontal">
                <H6 className="header-margin-right">Status</H6>
                <select id="status"
                        name="status"
                        value={status}
                        onChange={handleStatus}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="challenge-prop">
                <BrokenKeys brokenKeys={brokenKeys} setBrokenKeys={setBrokenKeys}/>
                <button className="add-button round-box"
                        onClick={addBrokenKey}
                        disabled={brokenKeys.length >= 16 || brokenKeys.includes("")}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Add broken key
                </button>
              </div>
            </div>
            <div className="challenge-prop">
              <H6>Sample Solution</H6>
              <input className="full-width round-box"
                     type="text"
                     value={sampleSolution}
                     onChange={handleSampleSolution}/>
            </div>
            <div className="challenge-prop">
              <H6>Initial Expression - only calculator buttons are allowed, no separators</H6>
              <input className="full-width round-box"
                     type="url"
                     value={initialExpression}
                     onChange={handleInitialExpression}/>
            </div>
            <div className="challenge-prop horizontal">
              <H6 className="header-margin-right">Comments</H6>
              <input className="full-width round-box"
                     type="text"
                     value={comments}
                     onChange={handleComments}/>
            </div>
          </div>
          <div className="preview-button-div" >
            <button className="preview-button round-box" onClick={handlePreview}>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;Preview challenge in rendering mode and save changes
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <PreviewCrazyCalculatorQuestionModal challenge={buildChallengeRequest()}
                                          isModalOpen={isPreviewModalOpen}
                                          setIsModalOpen={setIsPreviewModalOpen}/>
    </div>
  );
};
