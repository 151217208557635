import { useCalculatorActivityContext } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";
import { useLocalParticipantUserId } from "hooks/useLocalParticipant/useLocalParticipant";
import { useMembers } from "../../../livekit/useMembers/useMembers";

export const usePresentingUser = () => {
  const { presentingUserId } = useCalculatorActivityContext();
  const localUserId = useLocalParticipantUserId();
  const { members } = useMembers();

  return {
    presentingUserId,
    hasPresentingUser: !!presentingUserId,
    currentUserIsPresenting: presentingUserId === localUserId,
    presentingUserName: members.find((m) => m.userId === presentingUserId)?.participant.name,
    localUserId,
  };
};
