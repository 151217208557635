import { useSessionContext } from "hooks/useSession";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";

export const useAdminPermission = () => {
  const { authUserData } = useAuthUserData();
  const { session, update: updateSession } = useSessionContext();
  const admins = session?.admins;

  const hasAdminPermission =
    authUserData?.email === "luke@0is1.com" ||
    admins?.[authUserData?.id] === true ||
    session?.creatorId === authUserData?.id;

  const grantUserAdminPermission = () => {
    const userId = authUserData.id;
    updateSession({ admins: { [userId]: true } });
  };

  return { grantUserAdminPermission, hasAdminPermission };
};
