import { useState } from "react";
import { ListGroup, ListGroupItem, Button } from "@bootstrap-styled/v4";
import { useMembers } from "hooks/livekit/useMembers/useMembers";
import { Toggle } from "components/shared/Toggle/Toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBullhorn,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { prettyPrintFormula } from "utils";
import { useUserCalculator } from "hooks/activities/calculator/useUserCalculator/useUserCalculator";
import { useCalculatorActivity } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";
import { useHistory } from "hooks/activities/calculator/useHistory/useHistory";
import { History } from "components/pages/Session/Activity/activities/CalculatorActivity/History/History";

export const Users = () => {
  const { members } = useMembers({ includeObservers: true });

  const { presentingUserId, togglePresentingUser } = useCalculatorActivity();

  return (
    <div className="mb-3">
      <ListGroup>
        {members.map((member) => (
          <ListGroupItem key={member.participant.sid}>
            <Controls
              member={member}
              presentingUserId={presentingUserId}
              togglePresentingUser={togglePresentingUser}
            />
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

const Controls = ({ member, presentingUserId, togglePresentingUser }) => {
  const [showHistory, setShowHistory] = useState(false);
  const userId = member?.userId;
  const { calculator } = useUserCalculator(userId);

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Toggle
            checked={presentingUserId === userId}
            icons={{
              checked: <FontAwesomeIcon icon={faBullhorn} />,
              unchecked: <FontAwesomeIcon icon={faBullhorn} />,
            }}
            onChange={() => togglePresentingUser(userId)}
          />
          <span className="ml-2">{member?.participant?.name}</span>
        </div>

        <div className="d-flex align-items-center">
          {calculator?.formula && (
            <>
              <FontAwesomeIcon icon={faCalculator} />
              <span className="ml-2">
                {prettyPrintFormula(calculator?.formula)}
              </span>
            </>
          )}

          <Button
            style={{ width: 50 }}
            className="ml-2"
            onClick={() => setShowHistory(!showHistory)}
          >
            <FontAwesomeIcon icon={showHistory ? faAngleUp : faAngleDown} />
          </Button>
        </div>
      </div>
      {showHistory && <HistoryControls userId={userId} />}
    </div>
  );
};

const HistoryControls = ({ userId }) => {
  const { clear } = useHistory(userId);

  return (
    <div className="mt-3">
      <History height={300} userId={userId} style={{ width: "100%" }} />
      <Button className="mt-2" onClick={clear}>
        Clear History
      </Button>
    </div>
  );
};
