import React from "react";
import {
  SELECTED_VIDEO_INPUT_KEY,
} from "config/livekit";

import { VideoTrack } from "components/pages/Session/shared/VideoTrack/VideoTrack";
import useDevices from "hooks/livekit/useDevices/useDevices";
import useMediaStreamTrack from "hooks/livekit/useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";

export default function VideoInputList() {
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const localVideoTrack = localTracks.find((track) => track.kind === "video");
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId;
  const { activityId } = useSessionActivity();

  function replaceTrack(newDeviceId) {
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack.restart({
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <>
      {activityId !== "multiple-choice" && (
        <div>
          {localVideoTrack && (
            <div style={{ width: 300 }}>
              <VideoTrack isLocal track={localVideoTrack} />
            </div>
          )}
          {videoInputDevices.length > 1 ? (
            <div>
              Video Input
              <select
                onChange={(e) => replaceTrack(e.target.value)}
                value={localVideoInputDeviceId || ""}
              >
                {videoInputDevices.map((device) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <>
              Video Input
              {localVideoTrack?.mediaStreamTrack.label || "No Local Video"}
            </>
          )}
        </div>
      )}
    </>
  );
}
