import { generatePath } from "react-router";
import { routes } from "config/routes";

export const generateRoutePath = (name, params = {}) => {
  const route = routes.find((r) => r.name === name);
  return generatePath(route.path, params);
};

export const generateRouteUrl = (name, params = {}) => {
  const path = generateRoutePath(name, params);
  return `${window.origin}${path}`;
};
