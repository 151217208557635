import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace, faEquals } from "@fortawesome/free-solid-svg-icons";

import { Key } from "./Key";
import { Banned } from "./Banned";

export const Button = ({
  id = 1,
  banned,
  onClick = () => {},
  animationTrigger,
}) => {
  const icon = iconMap[id] || id;

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className="h-100 w-100"
        key={banned}
        initial={{ opacity: banned ? 0 : 1, transition: { duration: 0.3 } }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
      >
        {banned ? (
          <Banned id={id} icon={icon} />
        ) : (
          <Key
            id={id}
            icon={icon}
            onClick={onClick}
            animationTrigger={animationTrigger}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const iconMap = {
  "|": <FontAwesomeIcon icon={faBackspace} size="xs" />,
  "/": "÷",
  "-": "−",
  "*": "×",
  "=": <FontAwesomeIcon icon={faEquals} size="xs" />,
};
