import { motion } from "framer-motion";
import { usePresentingUser } from "hooks/activities/calculator/usePresentingUser/usePresentingUser";
import { P } from "@bootstrap-styled/v4";

import styled from "styled-components";

export const PresentingCalculatorWrapper = ({ children }) => {
  const { hasPresentingUser, currentUserIsPresenting, presentingUserName } =
    usePresentingUser();
  return (
    <Wrapper
      animate={{
        backgroundColor: hasPresentingUser ? "#607385AA" : "#00000000",
        paddingRight: hasPresentingUser ? "80px" : "0px",
        paddingLeft: hasPresentingUser ? "80px" : "0px",
        paddingTop: hasPresentingUser ? "8px" : "0px",
        paddingBottom: hasPresentingUser ? "8px" : "0px",
      }}
      style={{
        cursor:
          hasPresentingUser && !currentUserIsPresenting ? "not-allowed" : "",
      }}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          pointerEvents:
            hasPresentingUser && !currentUserIsPresenting ? "none" : " auto",
        }}
      >
        {children}
      </div>
      <P style={{ color: "white", marginTop: 8, marginBottom: 0 }}>
        {hasPresentingUser
          ? `${
              currentUserIsPresenting
                ? "Your"
                : presentingUserName + `'s`
            } screen is being shared with everyone.`
          : ""}
      </P>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
`;
